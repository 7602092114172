import { AxiosResponse } from 'axios';

import { SubmitEmailOTPRequestDTO } from '../../models/SubmitEmailOTPRequestDTO';
import { SubmitEmailOTPResponseDTO } from '../../models/SubmitEmailOTPResponseDTO';
import { SubmitEmailRequestDTO } from '../../models/SubmitEmailRequestDTO';
import { SubmitEmailResponseDTO } from '../../models/SubmitEmailResponseDTO';
import { SubmitPhoneOTPRequestDTO } from '../../models/SubmitPhoneOTPRequestDTO';
import { SubmitPhoneOTPResponseDTO } from '../../models/SubmitPhoneOTPResponseDTO';
import { SubmitPhoneRequestDTO } from '../../models/SubmitPhoneRequestDTO';
import { SubmitPhoneResponseDTO } from '../../models/SubmitPhoneResponseDTO';
import { IAuthNetworkAPIService } from './IAuthNetworkAPIService';
import { INetworkAPIHelper } from './networkAPIHelper/INetworkAPIHelper';

export namespace Endpoints {
  export const PHONE_TRIGGER_V2 = '/api/v2/phone/trigger';
  export const PHONE_OTP_VERIFY_V2 = '/api/v2/phone/verify/otp';
  export const EMAIL_OTP_TRIGGER_V2 = '/api/v2/user/login/email';
  export const RESEND_EMAIL_OTP_TRIGGER_V2 = '/api/v2/trigger/email/otp';
  export const EMAIL_OTP_VERIFY_V2 = '/api/v2/verify/email/otp';
}

export class AuthNetworkAPIService implements IAuthNetworkAPIService {
  networkAPIHelper: INetworkAPIHelper;

  constructor(networkAPIHelper: INetworkAPIHelper) {
    this.networkAPIHelper = networkAPIHelper;
  }

  //base url
  //knows which api to call
  async submitPhoneNumber(
    requestBody: SubmitPhoneRequestDTO
  ): Promise<SubmitPhoneResponseDTO> {
    try {
      const response: AxiosResponse<SubmitPhoneResponseDTO> =
        await this.networkAPIHelper.post(
          Endpoints.PHONE_TRIGGER_V2,
          requestBody
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async submitPhoneOTP(
    requestBody: SubmitPhoneOTPRequestDTO
  ): Promise<SubmitPhoneOTPResponseDTO> {
    try {
      const response: AxiosResponse<SubmitPhoneOTPResponseDTO> =
        await this.networkAPIHelper.post(
          Endpoints.PHONE_OTP_VERIFY_V2,
          requestBody
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async submitEmail(
    requestBody: SubmitEmailRequestDTO
  ): Promise<SubmitEmailResponseDTO> {
    try {
      const response: AxiosResponse<SubmitEmailResponseDTO> =
        await this.networkAPIHelper.post(
          Endpoints.EMAIL_OTP_TRIGGER_V2,
          requestBody
        );
      return response.data;
    } catch (error) {
      // console.error('Error submitting phone number:', error);
      throw error;
    }
  }

  async submitEmailOTP(
    requestBody: SubmitEmailOTPRequestDTO
  ): Promise<SubmitEmailOTPResponseDTO> {
    try {
      const response: AxiosResponse<SubmitPhoneOTPResponseDTO> =
        await this.networkAPIHelper.post(
          Endpoints.EMAIL_OTP_VERIFY_V2,
          requestBody
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async resendEmailOTP(
    requestBody: SubmitEmailRequestDTO
  ): Promise<SubmitEmailResponseDTO> {
    try {
      const response: AxiosResponse<SubmitEmailResponseDTO> =
        await this.networkAPIHelper.post(
          Endpoints.RESEND_EMAIL_OTP_TRIGGER_V2,
          requestBody
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
