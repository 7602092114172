import {
  TypographyConfigModel,
  IconLoaderButtonConfigModel,
  CapturePhoneNumberFieldConfigModel,
} from '@dfc-web/ui';

import { DFCAuthPageConfigModel } from '../../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';

//VM- View model for EnterPhone page
// should include
// tenant specific classes like font, color, icon, etc
export class EnterPhoneConfigModel implements DFCAuthPageConfigModel {
  public identifier: DFCAuthStepIdentifiers;
  public header: TypographyConfigModel;
  public title: TypographyConfigModel;
  public subTitle: TypographyConfigModel;
  public phoneNumberInput: CapturePhoneNumberFieldConfigModel;
  public nextButton: IconLoaderButtonConfigModel;

  constructor(identifier: DFCAuthStepIdentifiers) {
    this.identifier = identifier;
    this.header = {} as TypographyConfigModel;
    this.title = {} as TypographyConfigModel;
    this.subTitle = {} as TypographyConfigModel;
    this.nextButton = {} as IconLoaderButtonConfigModel;
    this.phoneNumberInput = {} as CapturePhoneNumberFieldConfigModel;
  }

  withHeader(header: TypographyConfigModel): EnterPhoneConfigModel {
    this.header = header;
    return this;
  }

  withTitle(title: TypographyConfigModel): EnterPhoneConfigModel {
    this.title = title;
    return this;
  }

  withSubTitle(subTitle: TypographyConfigModel): EnterPhoneConfigModel {
    this.subTitle = subTitle;
    return this;
  }

  withNextButton(
    nextButton: IconLoaderButtonConfigModel
  ): EnterPhoneConfigModel {
    this.nextButton = nextButton;
    return this;
  }

  withPhoneNumberInput(
    phoneNumberInput: CapturePhoneNumberFieldConfigModel
  ): EnterPhoneConfigModel {
    this.phoneNumberInput = phoneNumberInput;
    return this;
  }
}
