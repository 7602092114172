import { useRef } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';

import useOutsideClick from '../core/useOutsideClick';

interface ModalWrapperProps {
  closeHandler: () => void;
  children: React.ReactNode;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  closeHandler,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    closeHandler();
  });

  return ReactDOM.createPortal(
    <>
      <div
        data-testid="modal-backdrop"
        className={`${'min-h-[739px]'} h-full w-full flex justify-center items-center absolute top-0 rounded-md backdrop-blur`}
      ></div>
      <div
        className={`${'min-h-[739px]'} h-full w-full flex justify-center items-center absolute top-0`}
      >
        <div
          className="w-[327px] sm:w-[458px] md:w-[448px] xl:w-[490px] 2xl:w-[512px] h-4/5 lg:h-[600px] xl:h-[598px] 2xl:h-[640px] bg-white"
          ref={ref}
        >
          {children}
        </div>
      </div>
    </>,
    document.body
  );
};

export default ModalWrapper;
