import React from 'react';
import {
  ButtonConfigModel,
  CaptureOTPFieldConfigModel,
  CapturePhoneNumberFieldConfigModel,
  IconLoaderButtonConfigModel,
  InputFieldWithErrorConfigModel,
  TimerWithResendButtonConfigModel,
  TypographyConfigModel,
} from '@dfc-web/ui';

import { DFCAuthPageConfigModel } from '../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../models/DFCAuthStepIdentifiers';
import { AuthErrorPageBuilder } from '../pages/AuthError/AuthErrorPageBuilder';
import { AuthErrorStep } from '../pages/AuthError/AuthErrorStep';
import { EnterEmailConfigModel } from '../pages/EnterEmail/EnterEmailConfigModel';
import {
  EnterEmailOTPConfigModel,
  ResendEnterEmailOTPConfigModel,
} from '../pages/EnterEmailOTP/EnterEmailOTPConfigModel';
import { EnterPhoneConfigModel } from '../pages/EnterPhone/EnterPhoneConfigModel';
import {
  EnterPhoneOTPConfigModel,
  ResendEnterPhoneOTPConfigModel,
} from '../pages/EnterPhoneOTP/EnterPhoneOTPConfigModel';
import { IDFCAuthStep } from '../stepProcessor/DFCAuthStepProcessorProvider';
import { NextArrowSVG } from '../ui/NextArrowSVG';
import { Spinner } from '../ui/Spinner';
import { IAuthPageBuilder } from './IAuthPageBuilder';

export class DefaultPageBuilder implements IAuthPageBuilder {
  imagePrefix: string;

  constructor(imagePrefix: string) {
    this.imagePrefix = imagePrefix;
  }

  buildAuthErrorPage(step: IDFCAuthStep): DFCAuthPageConfigModel {
    const errorPageBuilder = new AuthErrorPageBuilder(
      step as AuthErrorStep,
      this.imagePrefix
    );
    return errorPageBuilder.build();
  }

  buildAuthCompletePage(): DFCAuthPageConfigModel {
    return {} as DFCAuthPageConfigModel;
  }

  //Sign In
  buildSignInEnterPhonePage(): DFCAuthPageConfigModel {
    return this.buildEnterPhonePage(DFCAuthStepIdentifiers.SignInEnterPhone);
  }

  buildSignInEnterPhoneOTPPage(): DFCAuthPageConfigModel {
    return this.buildEnterPhoneOTPPage(
      DFCAuthStepIdentifiers.SignInEnterPhoneOTP
    );
  }

  buildSignInEnterEmailOTPPage(): DFCAuthPageConfigModel | undefined {
    return this.buildEnterEmailOTPPage(
      DFCAuthStepIdentifiers.SignInEnterEmailOTP
    );
  }

  //Sign Up
  buildSignUpEnterPhonePage(): DFCAuthPageConfigModel | undefined {
    return this.buildEnterPhonePage(DFCAuthStepIdentifiers.SignUpEnterPhone);
  }

  buildSignUpEnterPhoneOTPPage(): DFCAuthPageConfigModel | undefined {
    return this.buildEnterPhoneOTPPage(
      DFCAuthStepIdentifiers.SignUpEnterPhoneOTP
    );
  }

  buildSignUpEnterEmailPage(): DFCAuthPageConfigModel | undefined {
    return this.buildEnterEmailPage(DFCAuthStepIdentifiers.SignUpEnterEmail);
  }

  buildSignUpEnterEmailOTPPage(): DFCAuthPageConfigModel | undefined {
    return this.buildEnterEmailOTPPage(
      DFCAuthStepIdentifiers.SignUpEnterEmailOTP
    );
  }

  private buildEnterPhonePage(
    identifier: DFCAuthStepIdentifiers
  ): DFCAuthPageConfigModel {
    const page = new EnterPhoneConfigModel(identifier)
      .withTitle(
        new TypographyConfigModel('Phone Number')
          .withVariant('h1')
          .withColor('text-t1-headline')
      )
      .withSubTitle(
        new TypographyConfigModel(
          'Welcome! Let’s find your account by entering your phone number.'
        )
          .withVariant('b4')
          .withColor('text-t1-headline')
      )
      .withNextButton(
        new IconLoaderButtonConfigModel(<Spinner />, <NextArrowSVG />)
      )
      .withPhoneNumberInput(
        new CapturePhoneNumberFieldConfigModel('Enter Phone Number')
      );

    page.withHeader(
      new TypographyConfigModel(
        identifier == DFCAuthStepIdentifiers.SignInEnterPhone
          ? 'SIGN IN'
          : 'SIGN UP'
      )
        .withVariant('c3')
        .withColor('text-br1-main')
    );

    return page;
  }

  private buildEnterEmailPage(
    identifier: DFCAuthStepIdentifiers
  ): DFCAuthPageConfigModel {
    const page = new EnterEmailConfigModel(identifier)
      .withTitle(
        new TypographyConfigModel('Email')
          .withVariant('h1')
          .withColor('text-t1-headline')
      )
      .withSubTitle(
        new TypographyConfigModel('Now enter your business email address.')
          .withVariant('b4')
          .withColor('text-t1-headline')
      )
      .withNextButton(
        new IconLoaderButtonConfigModel(<Spinner />, <NextArrowSVG />)
      )
      .withInputField(new InputFieldWithErrorConfigModel('Email'));

    page.withHeader(
      new TypographyConfigModel(
        identifier == DFCAuthStepIdentifiers.SignUpEnterEmail
          ? 'SIGN UP'
          : 'SIGN IN'
      )
        .withVariant('c3')
        .withColor('text-br1-main')
    );

    return page;
  }

  private buildEnterPhoneOTPPage(
    identifier: DFCAuthStepIdentifiers
  ): DFCAuthPageConfigModel {
    const page = new EnterPhoneOTPConfigModel(identifier)
      .withTitle(
        new TypographyConfigModel('Code')
          .withVariant('h1')
          .withColor('text-t1-headline')
      )
      .withSubTitle(
        new TypographyConfigModel('Thanks! We sent a code to ')
          .withVariant('b4')
          .withColor('text-t2-body')
      )
      .withOTPField(new CaptureOTPFieldConfigModel('Code'))
      .withTimerResendButton(
        new TimerWithResendButtonConfigModel(
          new TypographyConfigModel(
            'Didn’t receive the code? \nThen code will expire in '
          )
            .withVariant('b6')
            .withColor('text-t2-body'),
          new ButtonConfigModel('Resend Code')
            .withVariant('bn4')
            .withColor('text-br1-main')
        )
      )
      .withNextButton(
        new IconLoaderButtonConfigModel(<Spinner />, <NextArrowSVG />)
      )
      .withResendOTPConfig(
        new ResendEnterPhoneOTPConfigModel()
          .withTitle(
            new TypographyConfigModel('Your code expired Please try again.')
              .withVariant('h1')
              .withColor('text-t2-body')
          )
          .withSubTitle(
            new TypographyConfigModel(
              `We'll send the code to @@@ \nMessage and data rates may apply.`
            )
              .withVariant('b6')
              .withColor('text-t2-body')
          )
          .withResetButton(
            new ButtonConfigModel('Resend Code')
              .withVariant('bn3')
              .withColor('bg-br1-main text-t4-white')
          )
      );

    page.withHeader(
      new TypographyConfigModel(
        identifier == DFCAuthStepIdentifiers.SignInEnterPhoneOTP
          ? 'SIGN IN'
          : 'SIGN UP'
      )
        .withVariant('c3')
        .withColor('text-br1-main')
    );

    return page;
  }

  private buildEnterEmailOTPPage(
    identifier: DFCAuthStepIdentifiers
  ): DFCAuthPageConfigModel {
    const page = new EnterEmailOTPConfigModel(identifier)
      .withTitle(
        new TypographyConfigModel('Code')
          .withVariant('h1')
          .withColor('text-t1-headline')
      )
      .withSubTitle(
        new TypographyConfigModel('Thanks! We sent a code to ')
          .withVariant('b4')
          .withColor('text-t2-body')
      )
      .withOTPField(new CaptureOTPFieldConfigModel('Code'))
      .withTimerResendButton(
        new TimerWithResendButtonConfigModel(
          new TypographyConfigModel(
            'Didn’t receive the code? \nThen code will expire in '
          )
            .withVariant('b6')
            .withColor('text-t2-body'),
          new ButtonConfigModel('Resend Code')
            .withVariant('bn4')
            .withColor('text-br1-main')
        )
      )
      .withNextButton(
        new IconLoaderButtonConfigModel(<Spinner />, <NextArrowSVG />)
      )
      .withResendOTPConfig(
        new ResendEnterEmailOTPConfigModel()
          .withTitle(
            new TypographyConfigModel('Your code expired Please try again.')
              .withVariant('h1')
              .withColor('text-t2-body')
          )
          .withSubTitle(
            new TypographyConfigModel(`We'll send the code to @@@`)
              .withVariant('b6')
              .withColor('text-t2-body')
          )
          .withResetButton(
            new ButtonConfigModel('Resend Code')
              .withVariant('bn3')
              .withColor('bg-br1-main text-t4-white')
          )
      );

    page.withHeader(
      new TypographyConfigModel(
        identifier == DFCAuthStepIdentifiers.SignInEnterEmailOTP
          ? 'SIGN IN'
          : 'SIGN UP'
      )
        .withVariant('c3')
        .withColor('text-br1-main')
    );

    return page;
  }
}
