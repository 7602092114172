import { ButtonConfigModel, TypographyConfigModel } from '@dfc-web/ui';

import { DFCAuthPageConfigModel } from '../../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';
import {
  ActionButton,
  AuthErrorConfigModel,
  ErrorImageConfigModel,
  RedirectType,
} from './AuthErrorConfigModel';
import { AuthErrorStep } from './AuthErrorStep';

export class AuthErrorPageBuilder {
  errorStep: AuthErrorStep;
  imagePrefix: string;

  constructor(errorStep: AuthErrorStep, imagePrefix: string) {
    this.errorStep = errorStep;
    this.imagePrefix = imagePrefix;
  }

  build(): DFCAuthPageConfigModel {
    switch (this.errorStep.errorResponse.code) {
      case 'invalid_user':
      case 'web_login_not_allowed':
        return this.buildWithExitButtonAndTryAgain();
      case 'send_otp_error':
      case 'invalid_otp':
        return this.buildWithTryAgain();
      case 'existing_user_email':
        return this.buildWithExitButtonAndTryAnotherEmail();
      case 'third_party_account_not_found':
        return this.buildWithThirdPartyRedirect(this.errorStep);
    }
    return this.errorStep;
  }

  private buildWithExitButtonAndTryAgain(): DFCAuthPageConfigModel {
    const exitButton = this.buildExitButton('Exit', '/');
    const tryAgainButton = this.buildTryAgainButton(
      'Try Another Number',
      `${DFCAuthStepIdentifiers.SignInEnterPhone}`,
      RedirectType.INTERNAL
    );
    const errorImage = this.buildErrorImage(
      `${this.imagePrefix}/login-error.svg`,
      'w-[259px] h-[217px] sm:w-[315px] sm:h-[217px] md:w-[172px] md:h-[144px] xl:w-[196px] xl:h-[154px] 2xl:w-[212px] 2xl:h-[178px] mx-auto'
    );

    return this.buildAuthPageConfig(
      this.errorStep.identifier,
      errorImage,
      exitButton,
      tryAgainButton
    );
  }

  private buildWithTryAgain(): DFCAuthPageConfigModel {
    const tryAgainButton = this.buildTryAgainButton('Try Again', '/');
    const errorImage = this.buildErrorImage(
      `${this.imagePrefix}/login-error.svg`,
      'mx-auto'
    );
    return this.buildAuthPageConfig(
      this.errorStep.identifier,
      errorImage,
      tryAgainButton,
      undefined
    );
  }

  private buildWithThirdPartyRedirect(step: AuthErrorStep): DFCAuthPageConfigModel {
    const tryAgainButton = this.buildTryAgainButton('Create AMC Stubs Account', step.errorResponse.details?.create_account?.web_detail.url ?? "", RedirectType.EXTERNAL);
    const errorImage = this.buildErrorImage(
      `${this.imagePrefix}/login-error.svg`,
      'mx-auto'
    );
    return this.buildAuthPageConfig(
      this.errorStep.identifier,
      errorImage,
      tryAgainButton,
      undefined
    );
  }

  private buildWithExitButtonAndTryAnotherEmail(): DFCAuthPageConfigModel {
    const exitButton = this.buildExitButton('Exit', '/');
    const tryAnotherEmailButton = this.buildTryAnotherEmailButton(
      'Try Another Email',
      `${DFCAuthStepIdentifiers.SignUpEnterEmail}`,
      RedirectType.INTERNAL
    );
    const errorImage = this.buildErrorImage(
      `${this.imagePrefix}/onbStartPlaceholder.png`,
      'mx-auto'
    );

    return this.buildAuthPageConfig(
      this.errorStep.identifier,
      errorImage,
      exitButton,
      tryAnotherEmailButton
    );
  }

  private buildExitButton(label: string, path: string): ActionButton {
    return new ActionButton(
      'exit',
      new ButtonConfigModel(label)
        .withVariant('bn2')
        .withColor('bg-br1-main text-white'),
      path
    );
  }

  private buildTryAgainButton(
    label: string,
    path: string,
    redirectType?: RedirectType
  ): ActionButton {
    return new ActionButton(
      'try_again',
      new ButtonConfigModel(label)
        .withVariant('bn2')
        .withColor('bg-br1-main-15 text-br1-main'),
      path,
      redirectType
    );
  }

  private buildTryAnotherEmailButton(
    label: string,
    path: string,
    redirectType?: RedirectType
  ): ActionButton {
    return new ActionButton(
      'try_another_email',
      new ButtonConfigModel(label)
        .withVariant('bn2')
        .withColor('bg-br1-main-15 text-br1-main'),
      path,
      redirectType
    );
  }

  private buildErrorImage(
    src: string,
    className: string
  ): ErrorImageConfigModel {
    return new ErrorImageConfigModel(src, className);
  }

  private buildAuthPageConfig(
    identifier: DFCAuthStepIdentifiers,
    errorImage: ErrorImageConfigModel,
    exitButton: ActionButton,
    actionButton?: ActionButton
  ): DFCAuthPageConfigModel {
    const title = this.buildTypographyConfig(
      this.errorStep.errorResponse?.error?.display_message ??
        'Something went wrong',
      'h1',
      'text-t1-headline'
    );
    const subTitle = this.buildTypographyConfig(
      this.errorStep.errorResponse?.error?.display_description ??
        'Something went wrong',
      'b3',
      'text-t1-headline'
    );

    const actionButtons = [];
    if (exitButton) {
      actionButtons.push(exitButton);
    }
    if (actionButton) {
      actionButtons.push(actionButton);
    }

    return new AuthErrorConfigModel(identifier)
      .withTitle(title)
      .withSubTitle(subTitle)
      .withActionButtons(actionButtons)
      .withErrorImage(errorImage);
  }

  private buildTypographyConfig(
    text: string,
    variant: TypographyConfigModel['variants'],
    color: string
  ): TypographyConfigModel {
    return new TypographyConfigModel(text)
      .withVariant(variant)
      .withColor(color);
  }
}