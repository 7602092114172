"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptureOTPFieldConfigModel = void 0;
var CaptureOTPFieldConfigModel = (function () {
    function CaptureOTPFieldConfigModel(placeholder, title) {
        this.placeholder = placeholder;
        this.title = title;
    }
    return CaptureOTPFieldConfigModel;
}());
exports.CaptureOTPFieldConfigModel = CaptureOTPFieldConfigModel;
