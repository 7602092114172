import React, { useContext } from 'react';

import { IAuthService } from './IAuthService';

const AuthServiceContext = React.createContext<IAuthService | null>(null);

export const useAuthService = (): IAuthService => {
  const context = useContext(AuthServiceContext);
  if (!context) {
    throw new Error('useAuthService must be used within a AuthServiceProvider');
  }
  return context;
};

export const AuthServiceProvider: React.FC<{
  authService: IAuthService;
  children: React.ReactNode;
}> = ({ authService, children }) => {
  return (
    <AuthServiceContext.Provider value={authService}>
      {children}
    </AuthServiceContext.Provider>
  );
};
