import { TypographyConfigModel, ButtonConfigModel } from '@dfc-web/ui';

import { DFCAuthPageConfigModel } from '../../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';

export enum RedirectType {
  INTERNAL,
  EXTERNAL,
}

export class ActionButton {
  id: string;
  buttonConfig: ButtonConfigModel;
  redirectTo: string;
  redirectType: RedirectType = RedirectType.EXTERNAL;

  constructor(
    id: string,
    buttonConfig: ButtonConfigModel,
    redirectTo: string,
    redirectType: RedirectType = RedirectType.EXTERNAL
  ) {
    this.id = id;
    this.buttonConfig = buttonConfig;
    this.redirectTo = redirectTo;
    this.redirectType = redirectType;
  }
}

export class ErrorImageConfigModel {
  public errorImageBaseURL: string;
  public classNames: string;

  constructor(errorImageBaseURL: string, classNames: string) {
    this.errorImageBaseURL = errorImageBaseURL;
    this.classNames = classNames;
  }
}

export class AuthErrorConfigModel implements DFCAuthPageConfigModel {
  public identifier: DFCAuthStepIdentifiers;
  public title: TypographyConfigModel;
  public subTitle: TypographyConfigModel;
  public actionButtons: ActionButton[];
  public errorImage: ErrorImageConfigModel;

  constructor(identifier: DFCAuthStepIdentifiers) {
    this.identifier = identifier;
    this.title = {} as TypographyConfigModel;
    this.subTitle = {} as TypographyConfigModel;
    this.actionButtons = [] as ActionButton[];
    this.errorImage = {} as ErrorImageConfigModel;
  }

  withTitle(title: TypographyConfigModel): AuthErrorConfigModel {
    this.title = title;
    return this;
  }

  withSubTitle(subTitle: TypographyConfigModel): AuthErrorConfigModel {
    this.subTitle = subTitle;
    return this;
  }

  withActionButtons(actionButtons: ActionButton[]): AuthErrorConfigModel {
    this.actionButtons = actionButtons;
    return this;
  }

  withErrorImage(
    errorImageBaseURL: ErrorImageConfigModel
  ): AuthErrorConfigModel {
    this.errorImage = errorImageBaseURL;
    return this;
  }
}
