import {
  TypographyConfigModel,
  IconLoaderButtonConfigModel,
  InputFieldWithErrorConfigModel,
  CreateThirdPartyAccountConfigModel,
} from '@dfc-web/ui';

import { DFCAuthPageConfigModel } from '../../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';

//VM- View model for EnterPhone page
// should include
// tenant specific classes like font, color, icon, etc
export class EnterEmailConfigModel implements DFCAuthPageConfigModel {
  public identifier: DFCAuthStepIdentifiers;
  public header: TypographyConfigModel;
  public title: TypographyConfigModel;
  public subTitle: TypographyConfigModel;
  public inputField: InputFieldWithErrorConfigModel;
  public nextButton: IconLoaderButtonConfigModel;
  public thirdPartyAccountConfig?: CreateThirdPartyAccountConfigModel;

  constructor(identifier: DFCAuthStepIdentifiers) {
    this.identifier = identifier;
    this.header = {} as TypographyConfigModel;
    this.title = {} as TypographyConfigModel;
    this.subTitle = {} as TypographyConfigModel;
    this.nextButton = {} as IconLoaderButtonConfigModel;
    this.inputField = {} as InputFieldWithErrorConfigModel;
    this.thirdPartyAccountConfig  = {} as CreateThirdPartyAccountConfigModel;
  }

  withHeader(header: TypographyConfigModel): EnterEmailConfigModel {
    this.header = header;
    return this;
  }

  withTitle(title: TypographyConfigModel): EnterEmailConfigModel {
    this.title = title;
    return this;
  }

  withSubTitle(subTitle: TypographyConfigModel): EnterEmailConfigModel {
    this.subTitle = subTitle;
    return this;
  }

  withNextButton(
    nextButton: IconLoaderButtonConfigModel
  ): EnterEmailConfigModel {
    this.nextButton = nextButton;
    return this;
  }

  withInputField(
    inputField: InputFieldWithErrorConfigModel
  ): EnterEmailConfigModel {
    this.inputField = inputField;
    return this;
  }

  withThirdPartyAccountConfig(config: CreateThirdPartyAccountConfigModel): EnterEmailConfigModel {
    this.thirdPartyAccountConfig = config;
    return this;
  }
}
