import React from 'react';

import { AuthCompleteStep } from './AuthCompleteStep';

interface AuthCompleteProps {
  step: AuthCompleteStep;
}

const AuthComplete: React.FC<AuthCompleteProps> = (
  _props: AuthCompleteProps
) => {
  return (
    <div data-testid={'auth-complete-step'}>
      <div>Auth Successful. You can dismiss now</div>;
    </div>
  );
};

export default AuthComplete;
