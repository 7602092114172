"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogEmitter = exports.LogType = void 0;
var LogType;
(function (LogType) {
    LogType["DEBUG"] = "DEBUG";
    LogType["INFO"] = "INFO";
    LogType["WARN"] = "WARN";
    LogType["ERROR"] = "ERROR";
})(LogType = exports.LogType || (exports.LogType = {}));
var LogEmitter = (function () {
    function LogEmitter(onLogEvent, enableDebugMode) {
        this.enableDebugMode = false;
        this.onLogEvent = onLogEvent;
        this.enableDebugMode = enableDebugMode;
    }
    LogEmitter.prototype.error = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, LogType.ERROR);
    };
    LogEmitter.prototype.debug = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, LogType.DEBUG);
    };
    LogEmitter.prototype.info = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, LogType.INFO);
    };
    LogEmitter.prototype.warn = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, LogType.WARN);
    };
    LogEmitter.prototype.log = function (message, additionalInfo, type) {
        if (!this.enableDebugMode && type === LogType.DEBUG) {
            return;
        }
        this.onLogEvent(message, additionalInfo, type);
    };
    return LogEmitter;
}());
exports.LogEmitter = LogEmitter;
