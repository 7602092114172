import { IValidator, ValidationResult } from './IValidator';
import { Validation } from './Validator';

export enum EmailValidatorResultCode {
  REQUIRED = 'REQUIRED',
  INVALID = 'INVALID',
}

export interface EmailValidatorResult extends ValidationResult {
  code?: EmailValidatorResultCode;
}

export class EmailValidator implements IValidator {
  validation: Validation = {
    minLength: 1,
    maxLength: 50,
    validationRegex: '^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}',
  };

  constructor(validation: Validation | undefined = undefined) {
    if (validation) {
      this.validation = validation;
    }
  }

  validate(value: string): ValidationResult {
    if (value.length === 0) {
      return {
        isValid: false,
        code: EmailValidatorResultCode.REQUIRED,
      } as EmailValidatorResult;
    }
    const isValidEntry = new RegExp(this.validation.validationRegex).test(
      value.toString()
    );
    if (!isValidEntry) {
      return {
        isValid: false,
        code: EmailValidatorResultCode.INVALID,
      } as EmailValidatorResult;
    }

    return {
      isValid: true,
    } as EmailValidatorResult;
  }
}
