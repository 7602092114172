import React from 'react';

export const NextArrowSVG = () => {
  return (
    <svg
      data-testid="next-arrow-svg"
      className={`mx-auto fill-br1-main`}
      width="65"
      height="62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 0C12.536 0 0 12.536 0 28v6c0 15.464 12.536 28 28 28h9c15.464 0 28-12.536 28-28v-6C65 12.536 52.464 0 37 0h-9zm6.429 20.56c.683-.746 1.855-.746 2.587 0l8.447 8.603c.342.349.537.846.537 1.343 0 .498-.195.995-.537 1.343l-8.447 8.604c-.39.348-.83.547-1.318.547-.488 0-.977-.199-1.318-.547-.733-.696-.733-1.89 0-2.636l5.322-5.42H20.855c-1.025 0-1.855-.846-1.855-1.89 0-1.045.83-1.89 1.855-1.89h18.896l-5.322-5.422c-.733-.696-.733-1.89 0-2.636z"
      ></path>
    </svg>
  );
};
