export const AnalyticsEvents = {
  api: {
    phone: {
      apiSuccess: 'dfc_auth_phone_initiate_succeed',
      apiFailed: 'dfc_auth_phone_initiate_failed',
    },
    phoneOtp: {
      apiSuccess: 'dfc_auth_phone_verify_otp_succeed',
      apiFailed: 'dfc_auth_phone_verify_otp_failed',
      resendApiSuccess: 'dfc_auth_resend_phone_otp_succeed',
      resendApiFailed: 'dfc_auth_resend_phone_otp_failed',
    },
    email: {
      apiSuccess: 'dfc_auth_email_initiate_succeed',
      apiFailed: 'dfc_auth_email_initiate_failed',
      resendApiSuccess: 'dfc_auth_resend_email_otp_succeed',
      resendApiFailed: 'dfc_auth_resend_email_otp_failed',
    },
    emailOtp: {
      apiSuccess: 'dfc_auth_email_verify_otp_succeed',
      apiFailed: 'dfc_auth_email_verify_otp_failed',
    },
  },
  signUp: {
    phone: {
      screen: 'dfc_auth_signup_phone_screen',
      nextClicked: 'dfc_auth_signup_phone_next_clicked',
    },
    phoneOtp: {
      screen: 'dfc_auth_signup_phone_otp_screen',
      nextClicked: 'dfc_auth_signup_phone_otp_next_clicked',
      resendClicked: 'dfc_auth_signup_phone_otp_resend_clicked',
      error: 'dfc_auth_otp_error_screen',
      expired: 'dfc_auth_signup_phone_otp_expired',
    },
    email: {
      screen: 'dfc_auth_signup_email_screen',
      nextClicked: 'dfc_auth_signup_email_next_clicked',
    },
    emailOtp: {
      screen: 'dfc_auth_signup_email_otp_screen',
      resendClicked: 'dfc_auth_signup_email_otp_resend_clicked',
      error: 'dfc_auth_otp_error_screen',
      nextClicked: 'dfc_auth_signup_email_otp_next_clicked',
      expired: 'dfc_auth_signup_email_otp_expired',
    },
    authError: {
      existingEmail: 'dfc_auth_existing_email_screen',
      screen: 'dfc_auth_session_error_screen',
      nextClicked: 'dfc_auth_signup_error_next_clicked',
      noAccount: 'dfc_auth_noaccount_screen',
      sendOTPError: 'dfc_auth_otp_sending_error_screen',
      tryAnotherNumber: 'dfc_auth_noaccount_tryanother_clicked',
      noAccountExitClicked: 'dfc_auth_noaccount_exit_clicked',
    },
  },
  signIn: {
    phone: {
      screen: 'dfc_auth_signin_phone_screen',
      nextClicked: 'dfc_auth_signin_phone_next_clicked',
    },
    phoneOtp: {
      screen: 'dfc_auth_signin_phone_otp_screen',
      nextClicked: 'dfc_auth_signin_phone_otp_next_clicked',
      resendClicked: 'dfc_auth_signin_phone_otp_resend_clicked',
      expired: 'dfc_auth_signin_phone_otp_expired',
      error: 'dfc_auth_otp_error_screen',
    },
    emailOtp: {
      screen: 'dfc_auth_signin_email_otp_screen',
      nextClicked: 'dfc_auth_signin_email_otp_next_clicked',
      resendClicked: 'dfc_auth_signin_email_otp_resend_clicked',
      error: 'dfc_auth_otp_error_screen',
      expired: 'dfc_auth_signin_email_otp_expired',
    },
    authError: {
      existingEmail: 'dfc_auth_existing_email_screen',
      screen: 'dfc_auth_session_error_screen',
      nextClicked: 'dfc_auth_signin_error_next_clicked',
      noAccount: 'dfc_auth_noaccount_screen',
      sendOTPError: 'dfc_auth_otp_sending_error_screen',
      tryAnotherNumber: 'dfc_auth_noaccount_tryanother_clicked',
      noAccountExitClicked: 'dfc_auth_noaccount_exit_clicked',
    },
  },
};

// static let emailOTPScreenViewed = DFCAnalyticsEventName(rawValue: "dfc_auth_email_otp_screen")
//     static let emailScreenViewed = DFCAnalyticsEventName(rawValue: "dfc_auth_email_screen")
//     static let noAccountScreenViewed = DFCAnalyticsEventName(rawValue: "dfc_auth_noaccount_screen")
//     static let thirdPartyNoAccountScreenViewed = DFCAnalyticsEventName(rawValue: "dfc_auth_third_party_account_not_found_screen")
//     static let emailNextClicked = DFCAnalyticsEventName(rawValue: "dfc_auth_email_next_clicked")
//     static let emailCreateAccountClicked = DFCAnalyticsEventName(rawValue: "dfc_auth_email_create_account_clicked")
//     static let thirdPartyCreateAccountClicked = DFCAnalyticsEventName(rawValue: "dfc_auth_third_party_create_account_clicked")
//     static let thirdPartyNoAccountBackClicked = DFCAnalyticsEventName(rawValue: "dfc_auth_third_party_account_not_found_back_clicked")
