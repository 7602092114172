import React, { useCallback, useEffect } from 'react';

import { DFCAuthStepIdentifiers } from '../models/DFCAuthStepIdentifiers';

export interface IDFCAuthStepProcessorContextState {
  currentStep: IDFCAuthStep;
  steps: IDFCAuthStep[];
  addNewStep(step: IDFCAuthStep): void;
  goBack(): void;
  popTo(stepIdentifier: DFCAuthStepIdentifiers): boolean;
}

export interface IDFCAuthStep {
  identifier: DFCAuthStepIdentifiers;
}

/**
 * Context to manage the state of the authentication step processor
 * Every step should know the next step to be processed and need to be added to the context
 * The context will manage the current step and the steps stack
 * Whenever a new step is added, it will be pushed to the stack
 * Whenever a step wants to go back, it will pop the stack
 * CurrentStep will always be the top of the stack
 */
export const DFCAuthProcessorContext =
  React.createContext<IDFCAuthStepProcessorContextState>({
    currentStep: { identifier: DFCAuthStepIdentifiers.SignInEnterPhone },
    steps: [],
    addNewStep: (_step: IDFCAuthStep) => {},
    goBack: () => {},
    popTo: (_stepIdentifier: DFCAuthStepIdentifiers) => {
      return false;
    },
  });

export function DFCAuthStepProcessorProvider({
  children,
  initialStep,
}: {
  children: React.ReactNode;
  initialStep: IDFCAuthStep;
}) {
  const [currentStep, setCurrentStep] =
    React.useState<IDFCAuthStep>(initialStep);
  const [stepStack, setStepStack] = React.useState<IDFCAuthStep[]>([
    initialStep,
  ]);

  const pushToStack = (step: IDFCAuthStep) => {
    setStepStack((prevStack) => [...prevStack, step]);
  };

  const popFromStack = () => {
    setStepStack((prevStack) => prevStack.slice(0, -1));
  };

  const getTopOfStack = useCallback(() => {
    return stepStack[stepStack.length - 1];
  }, [stepStack]);

  const addNewStep = (step: IDFCAuthStep) => {
    pushToStack(step);
  };

  const popTo = (stepIdentifier: DFCAuthStepIdentifiers) => {
    const index = stepStack.findIndex((s) => s.identifier === stepIdentifier);
    if (index === -1) {
      return false;
    }
    setStepStack((prevStack) => prevStack.slice(0, index + 1));
    return true;
  };

  const goBack = () => {
    popFromStack();
  };

  useEffect(() => {
    setCurrentStep(getTopOfStack());
  }, [stepStack]);

  return (
    <DFCAuthProcessorContext.Provider
      value={{
        currentStep,
        steps: stepStack,
        addNewStep,
        goBack,
        popTo,
      }}
    >
      {children}
    </DFCAuthProcessorContext.Provider>
  );
}
