"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonConfigModel = void 0;
var ButtonConfigModel = (function () {
    function ButtonConfigModel(text) {
        this.text = text;
    }
    ButtonConfigModel.prototype.withColor = function (color) {
        this.color = color;
        return this;
    };
    ButtonConfigModel.prototype.withVariant = function (variant) {
        this.variants = variant;
        return this;
    };
    return ButtonConfigModel;
}());
exports.ButtonConfigModel = ButtonConfigModel;
