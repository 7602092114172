import React, { ChangeEvent } from 'react';
import {
  Typography,
  IconLoaderButton,
  InputFieldWithError,
  CreateThirdPartyAccount,
  CreateThirdPartyAccountConfigModel,
} from '@dfc-web/ui';

import { EnterEmailConfigModel } from './EnterEmailConfigModel';
import { EnterEmailStep } from './EnterEmailStep';
import useEnterEmail from './useEnterEmail';

interface EnterEmailProps {
  config: EnterEmailConfigModel;
  step: EnterEmailStep;
}

const EnterEmail: React.FC<EnterEmailProps> = (props: EnterEmailProps) => {
  const {
    submitting,
    error,
    isValidData,
    email,
    handleEmailChange,
    handleSubmit,
  } = useEnterEmail(props.step);

  const config = props.config;
  const updatedThirdPartyAccountConfig = {
    ...config.thirdPartyAccountConfig,
    redirectURL: props.step.thirdPartyCreateAccount?.redirectionURL ?? "",
  } as CreateThirdPartyAccountConfigModel;

  const onSubmit = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    handleSubmit();
  };

  const renderNextButton = () => {
      return (
        <>
         {
          config.thirdPartyAccountConfig && (
            <div className="pt-8">
              <CreateThirdPartyAccount config={updatedThirdPartyAccountConfig} />
            </div>
          )
         }
          <div className="pt-16 text-center">
            <IconLoaderButton
              type="button"
              classes='"block m-auto"'
              config={config.nextButton}
              submitting={submitting}
              disabled={!isValidData}
              onClick={onSubmit}
            />
          </div>
        </>
      )    
  }

  return (
    <div data-testid={config.identifier}>
      {config.header && (
        <div className="mt-3">
          <Typography config={config.header} />
        </div>
      )}
      <div className="my-6">
        <Typography config={config.title} />
      </div>
      <div className="my-6">
        <Typography config={config.subTitle} />
      </div>
      <form onSubmit={onSubmit}>
        <InputFieldWithError
          config={config.inputField}
          value={email}
          error={error}
          onChange={(value: ChangeEvent<HTMLInputElement>) => {
            const formattedValue = value.target.value;
            handleEmailChange(formattedValue);
          }}
        />
        {renderNextButton()}
      </form>
    </div>
  );
};

export default EnterEmail;
