import React from 'react';
import { Typography, Button } from '@dfc-web/ui';

import {
  ActionButton,
  AuthErrorConfigModel,
  RedirectType,
} from './AuthErrorConfigModel';
import { AuthErrorStep } from './AuthErrorStep';
import useAuthError from './useAuthError';

interface AuthErrorProps {
  config: AuthErrorConfigModel;
  step: AuthErrorStep;
}

const AuthError: React.FC<AuthErrorProps> = (props: AuthErrorProps) => {
  const { handleInternalRedirect } = useAuthError(props.step);

  const config = props.config;

  const handleRedirect = (button: ActionButton) => {
    if (button.redirectType === RedirectType.EXTERNAL) {
      window.location.href = button.redirectTo;
    } else {
      handleInternalRedirect(button);
    }
  };

  return (
    <div
      data-testid={config.identifier}
      className="w-screen h-screen absolute bg-white top-0 left-0 px-7 py-10 sm:px-14 md:px-10 md:py-8 lg:px-16 lg:py-10  flex flex-1 flex-col justify-between"
    >
      <div className="mx-auto text-center max-w-[315px] sm:max-w-full sm:w-[350px] md:w-[546px] xl:w-[619px] 2xl:w-[667px]">
        <Typography config={config.title} />
      </div>
      <div className="mx-auto text-center mt-2 mb-[108px] sm:mt-3 sm:mb-[112px] md:mt-4 md:mb-16 2xl:mb-[68px] max-w-[315px] sm:max-w-full sm:w-[316px] md:w-auto">
        <Typography config={config.subTitle} />
      </div>
      <img
        className={`${config.errorImage.classNames}`}
        src={`${config.errorImage.errorImageBaseURL}`}
        alt="not found"
      />
      <div className="flex md:gap-2 flex-col md:flex-row items-center justify-center mt-20 sm:mt-[76px] md:mt-[68px] 2xl:mt-[72px] pb-10 sm:pb-20">
        {config.actionButtons.map((button, index) => {
          return (
            <Button
              key={index}
              config={button.buttonConfig}
              onClick={() => handleRedirect(button)}
              classNames={
                'flex items-center justify-center w-full mt-4 text-center rounded-full focus:outline-none max-w-[328px] sm:max-w-full h-[72px] sm:w-[315px] sm:h-[66px] md:w-[266px] md:h-[66px] xl:w-[302px] 2xl:w-[326px]'
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default AuthError;
