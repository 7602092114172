import { useEffect } from 'react';

const useOutsideClick = (ref: any, callback: any) => {
  useEffect(() => {
    const handleClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback(ref);
      }
    };

    if (ref.current) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback, ref]);

  return ref;
};

export default useOutsideClick;
