import { AuthNetworkAPIService } from './AuthNetworkAPIService';
import { IAuthNetworkAPIService } from './IAuthNetworkAPIService';
import { NetworkAPIHelper } from './networkAPIHelper/NetworkAPIHelper';

export class AuthNetworkServiceFactory {
  private networkAPIHelper: NetworkAPIHelper;
  constructor() {
    this.networkAPIHelper = new NetworkAPIHelper();
  }

  getNetworkService(): IAuthNetworkAPIService {
    return new AuthNetworkAPIService(this.networkAPIHelper);
  }
}
