"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconLoaderButtonConfigModel = void 0;
var IconLoaderButtonConfigModel = (function () {
    function IconLoaderButtonConfigModel(loader, nextIcon) {
        this.loader = loader;
        this.nextIcon = nextIcon;
    }
    return IconLoaderButtonConfigModel;
}());
exports.IconLoaderButtonConfigModel = IconLoaderButtonConfigModel;
