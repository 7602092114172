"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimerWithResendButton = void 0;
var Button_1 = require("../../atoms/button/Button");
var Typography_1 = require("../../atoms/typography/Typography");
var react_1 = __importStar(require("react"));
var TimerWithResendButton = function (_a) {
    var config = _a.config, timerValue = _a.timerValue, onTimerEnd = _a.onTimerEnd, onResend = _a.onResend;
    var coolDownPeriod = 30;
    var _b = (0, react_1.useState)(timerValue), timeLeft = _b[0], setTimeLeft = _b[1];
    var _c = (0, react_1.useState)(true), isRunning = _c[0], setIsRunning = _c[1];
    var _d = (0, react_1.useState)(coolDownPeriod), cooldown = _d[0], setCooldown = _d[1];
    (0, react_1.useEffect)(function () {
        if (isRunning && timeLeft > 0) {
            var timerId_1 = setInterval(function () {
                setTimeLeft(function (prevTime) { return prevTime - 1; });
            }, 1000);
            return function () { return clearInterval(timerId_1); };
        }
        else if (timeLeft === 0) {
            onTimerEnd();
        }
    }, [isRunning, timeLeft, onTimerEnd]);
    (0, react_1.useEffect)(function () {
        if (cooldown > 0) {
            var cooldownId_1 = setInterval(function () {
                setCooldown(function (prevCooldown) { return prevCooldown - 1; });
            }, 1000);
            return function () { return clearInterval(cooldownId_1); };
        }
    }, [cooldown]);
    var handleResendClick = (0, react_1.useCallback)(function () {
        onTimerEnd();
        setIsRunning(false);
        onResend();
        setCooldown(coolDownPeriod);
        setTimeLeft(timerValue);
        setIsRunning(true);
    }, [timerValue, onResend]);
    var formatTime = function (seconds) {
        var minutes = Math.floor(seconds / 60);
        var remainingSeconds = seconds % 60;
        return minutes.toString().padStart(2, "0") + ":" + remainingSeconds
            .toString()
            .padStart(2, "0");
    };
    var timerLabel = config.title.text + "" + formatTime(timeLeft);
    var updatedConfig = __assign(__assign({}, config.title), { text: timerLabel });
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: "py-2" },
            react_1.default.createElement(Typography_1.Typography, { config: updatedConfig })),
        react_1.default.createElement("div", { className: cooldown > 0
                ? "my-6 pointer-events-none opacity-40"
                : "my-6 cursor-pointer" },
            react_1.default.createElement(Button_1.Button, { config: config.resendButton, onClick: handleResendClick, disabled: cooldown > 0 }))));
};
exports.TimerWithResendButton = TimerWithResendButton;
