"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateThirdPartyAccountConfigModel = void 0;
var CreateThirdPartyAccountConfigModel = (function () {
    function CreateThirdPartyAccountConfigModel(title, button, redirectURL) {
        this.title = title;
        this.button = button;
        this.redirectURL = redirectURL;
    }
    return CreateThirdPartyAccountConfigModel;
}());
exports.CreateThirdPartyAccountConfigModel = CreateThirdPartyAccountConfigModel;
