import { PhoneNumberUtil } from '../util/PhoneNumberUtil';
import { IValidator, ValidationResult } from './IValidator';
import { Validation } from './Validator';

export enum PhoneValidatorResultCode {
  REQUIRED = 'REQUIRED',
  INVALID_COUNTRY_CODE = 'INVALID_COUNTRY_CODE',
  INVALID_NUMBERS = 'INVALID_NUMBERS',
  INVALID_LENGTH = 'INVALID_LENGTH',
}

export interface PhoneValidatorResult extends ValidationResult {
  code?: PhoneValidatorResultCode;
}

export class PhoneValidator implements IValidator {
  validation: Validation = {
    minLength: 10,
    maxLength: 13,
    validationRegex: `^[0-9]{10}\\d*$`,
  };

  constructor(validation: Validation | undefined = undefined) {
    if (validation) {
      this.validation = validation;
    }
  }
  validate(value: string): ValidationResult {
    const phoneNumber = PhoneNumberUtil.unFormattedPhoneNumber(value);

    if (phoneNumber.length === 0) {
      return {
        isValid: false,
        code: PhoneValidatorResultCode.REQUIRED,
      } as PhoneValidatorResult;
    }

    const hasUScountryCode = new RegExp(/^1/).test(phoneNumber.toString());
    if (hasUScountryCode) {
      return {
        isValid: false,
        code: PhoneValidatorResultCode.INVALID_COUNTRY_CODE,
      } as PhoneValidatorResult;
    }

    const isValidEntry = new RegExp(this.validation.validationRegex).test(
      phoneNumber.toString()
    );
    if (!isValidEntry) {
      return {
        isValid: false,
        code: PhoneValidatorResultCode.INVALID_NUMBERS,
      } as PhoneValidatorResult;
    }

    const isInRange =
      Number(phoneNumber) > 1 &&
      phoneNumber.toString().length >= this.validation.minLength &&
      `+1${phoneNumber.toString()}`.length <= this.validation.maxLength;
    if (!isInRange) {
      return {
        isValid: false,
        code: PhoneValidatorResultCode.INVALID_LENGTH,
      } as PhoneValidatorResult;
    }

    return {
      isValid: true,
    } as PhoneValidatorResult;
  }
}
