import { DFCAuthProps } from '../../DFCAuth';
import { DFCAuthFlow } from '../../models/DFCAuthFlows';
import { DFCAuthErrorModel } from '../../models/DFCAuthOutputModel';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';
import { ErrorResponseDTO } from '../../models/ErrorResponseDTO';
import { SubmitEmailOTPRequestDTO } from '../../models/SubmitEmailOTPRequestDTO';
import { SubmitEmailOTPResponseDTO } from '../../models/SubmitEmailOTPResponseDTO';
import { SubmitEmailRequestDTO } from '../../models/SubmitEmailRequestDTO';
import { SubmitEmailResponseDTO } from '../../models/SubmitEmailResponseDTO';
import { SubmitPhoneOTPRequestDTO } from '../../models/SubmitPhoneOTPRequestDTO';
import { SubmitPhoneOTPResponseDTO } from '../../models/SubmitPhoneOTPResponseDTO';
import { SubmitPhoneRequestDTO } from '../../models/SubmitPhoneRequestDTO';
import { SubmitPhoneResponseDTO } from '../../models/SubmitPhoneResponseDTO';
import { AuthCompleteStep } from '../../pages/AuthCompleteStep/AuthCompleteStep';
import { AuthErrorStep } from '../../pages/AuthError/AuthErrorStep';
import { EnterEmailStep } from '../../pages/EnterEmail/EnterEmailStep';
import { EnterEmailOTPStep } from '../../pages/EnterEmailOTP/EnterEmailOTPStep';
import { EnterPhoneOTPStep } from '../../pages/EnterPhoneOTP/EnterPhoneOTPStep';
import { IAuthNetworkAPIService } from '../../services/networkService/IAuthNetworkAPIService';
import { IDFCAuthStep } from '../../stepProcessor/DFCAuthStepProcessorProvider';
import { IAuthService } from './IAuthService';

/**
 * Dev note: We can have different interfaces for different services.
 * So we can slice the auth service and inject the required interface in component
 * This will help in reducing the size of the context and also help in better code splitting
 * But this is not a hard and fast rule. It depends on the use case.
 * For now, we are using a single interface for all services.
 */
export class AuthService implements IAuthService {
  authNetworkAPIHelper: IAuthNetworkAPIService;
  authProps: DFCAuthProps;
  constructor(
    networkAPIHelper: IAuthNetworkAPIService,
    authProps: DFCAuthProps
  ) {
    this.authNetworkAPIHelper = networkAPIHelper;
    this.authProps = authProps;
  }

  closeAuthSession(withError?: DFCAuthErrorModel): void {
    const error = withError
      ? withError
      : {
          code: 'auth_session_closed',
          message: 'Auth Session has been closed',
        };

    this.authProps.onAuthFailure(error);

    this.authProps.onDismiss();
  }

  async submitPhoneNumber(phoneNumber: string): Promise<IDFCAuthStep> {
    const requestBody: SubmitPhoneRequestDTO = {
      phone: phoneNumber,
      tenant_id: this.authProps.tenant,
    };

    try {
      const response: SubmitPhoneResponseDTO =
        await this.authNetworkAPIHelper.submitPhoneNumber(requestBody);

      if (response.next_screen.identifier === 'capture_phone_otp') {
        return {
          identifier:
            this.authProps.flow === DFCAuthFlow.AuthFlowSignIn
              ? DFCAuthStepIdentifiers.SignInEnterPhoneOTP
              : DFCAuthStepIdentifiers.SignUpEnterPhoneOTP,
          phone: response.phone,
          timer: response.next_screen.capture_phone_otp.timer,
          validation: {
            minLength:
              response.next_screen.capture_phone_otp.validation.phone_otp
                .min_length,
            maxLength:
              response.next_screen.capture_phone_otp.validation.phone_otp
                .max_length,
            validationRegex:
              response.next_screen.capture_phone_otp.validation.phone_otp.regex,
          },
        } as EnterPhoneOTPStep;
      }
      throw new Error('Unknown next screen');
    } catch (error) {
      throw error;
    }
  }

  async submitPhoneOTP(
    otpRequest: SubmitPhoneOTPRequestDTO
  ): Promise<IDFCAuthStep> {
    try {
      const updatedRequest: SubmitPhoneOTPRequestDTO = {
        ...otpRequest,
        tenant_id: this.authProps.tenant,
      };

      const response: SubmitPhoneOTPResponseDTO =
        await this.authNetworkAPIHelper.submitPhoneOTP(updatedRequest);

      if (response.id_token && response.refresh_token) {
        this.authProps.onAuthSuccess({
          idToken: response.id_token,
          refreshToken: response.refresh_token,
          phone: otpRequest.phone,
        });
        return {
          identifier: DFCAuthStepIdentifiers.AuthCompleteStep,
        } as AuthCompleteStep;
      }

      if (
        response.next_screen &&
        response.next_screen?.identifier === 'capture_name_email'
      ) {
        return {
          identifier:
            this.authProps.flow === DFCAuthFlow.AuthFlowSignIn
              ? DFCAuthStepIdentifiers.SignInEnterEmail
              : DFCAuthStepIdentifiers.SignUpEnterEmail,
          phone: otpRequest.phone,
          phoneOTP: otpRequest.otp,
          validation: {
            minLength:
              response.next_screen.capture_name_email?.validation.email
                .min_length,
            maxLength:
              response.next_screen.capture_name_email?.validation.email
                .max_length,
            validationRegex:
              response.next_screen.capture_name_email?.validation.email.regex,
          },
        } as EnterEmailStep;
      } else if (
        response.next_screen &&
        response.next_screen?.identifier === 'capture_name_email_amc'
      ) {
        return {
          identifier:
            this.authProps.flow === DFCAuthFlow.AuthFlowSignIn
              ? DFCAuthStepIdentifiers.SignInEnterEmail
              : DFCAuthStepIdentifiers.SignUpEnterEmail,
          phone: otpRequest.phone,
          phoneOTP: otpRequest.otp,
          validation: {
            minLength:
              response.next_screen.capture_name_email_amc?.validation.email
                .min_length,
            maxLength:
              response.next_screen.capture_name_email_amc?.validation.email
                .max_length,
            validationRegex:
              response.next_screen.capture_name_email_amc?.validation.email
                .regex,
          },
          thirdPartyCreateAccount: {
            type: response.next_screen.capture_name_email_amc?.create_account
              ?.type,
            redirectionURL:
              response.next_screen.capture_name_email_amc?.create_account
                ?.web_detail.url,
          },
        } as EnterEmailStep;
      } else if (
        response.next_screen &&
        response.next_screen?.identifier === 'capture_email_otp'
      ) {
        return {
          identifier:
            this.authProps.flow === DFCAuthFlow.AuthFlowSignIn
              ? DFCAuthStepIdentifiers.SignInEnterEmailOTP
              : DFCAuthStepIdentifiers.SignUpEnterEmailOTP,
          phone: otpRequest.phone,
          phoneOTP: otpRequest.otp,
          email: response.next_screen.capture_email_otp?.email,
          timer: response.next_screen.capture_email_otp?.timer,
          otpHelpText: response.next_screen.capture_email_otp?.otp_help_text,
          validation: {
            minLength:
              response.next_screen.capture_email_otp?.validation.email_otp
                .min_length,
            maxLength:
              response.next_screen.capture_email_otp?.validation.email_otp
                .max_length,
            validationRegex:
              response.next_screen.capture_email_otp?.validation.email_otp
                .regex,
          },
        } as EnterEmailOTPStep;
      }
      throw new Error('Unknown next screen: Not yet implemented');
    } catch (error: any) {
      return this.handleErrorResponseForPhoneOTP(error);
    }
  }

  async submitEmail(
    emailRequest: SubmitEmailRequestDTO
  ): Promise<IDFCAuthStep> {
    const requestBody: SubmitEmailRequestDTO = {
      ...emailRequest,
      tenant_id: this.authProps.tenant,
    };

    try {
      const response: SubmitEmailResponseDTO =
        await this.authNetworkAPIHelper.submitEmail(requestBody);

      if (response.next_screen.identifier === 'capture_email_otp') {
        return {
          identifier:
            this.authProps.flow === DFCAuthFlow.AuthFlowSignIn
              ? DFCAuthStepIdentifiers.SignInEnterEmailOTP
              : DFCAuthStepIdentifiers.SignUpEnterEmailOTP,
          phone: emailRequest.phone,
          phoneOTP: emailRequest.phoneOtp,
          email: response.next_screen.capture_email_otp.email,
          timer: response.next_screen.capture_email_otp.timer,
          otpHelpText: response.next_screen.capture_email_otp.otp_help_text,
          validation: {
            minLength:
              response.next_screen.capture_email_otp.validation.email_otp
                .min_length,
            maxLength:
              response.next_screen.capture_email_otp.validation.email_otp
                .max_length,
            validationRegex:
              response.next_screen.capture_email_otp.validation.email_otp.regex,
          },
        } as EnterEmailOTPStep;
      }
      throw new Error('Unknown next screen');
    } catch (error) {
      throw error;
    }
  }
  async resendEmailOTP(
    emailRequest: SubmitEmailRequestDTO
  ): Promise<IDFCAuthStep> {
    const requestBody: SubmitEmailRequestDTO = {
      ...emailRequest,
      is_resend: true,
      otp: emailRequest.phoneOtp, // in case of resend it asks for phone otp
      tenant_id: this.authProps.tenant,
    };

    try {
      const response: SubmitEmailResponseDTO =
        await this.authNetworkAPIHelper.resendEmailOTP(requestBody);

      if (response.next_screen.identifier === 'capture_email_otp') {
        return {
          identifier:
            this.authProps.flow === DFCAuthFlow.AuthFlowSignIn
              ? DFCAuthStepIdentifiers.SignInEnterEmailOTP
              : DFCAuthStepIdentifiers.SignUpEnterEmailOTP,
          phone: emailRequest.phone,
          phoneOTP: emailRequest.phoneOtp,
          email: response.next_screen.capture_email_otp.email,
          timer: response.next_screen.capture_email_otp.timer,
          otpHelpText: response.next_screen.capture_email_otp.otp_help_text,
          validation: {
            minLength:
              response.next_screen.capture_email_otp.validation.email_otp
                .min_length,
            maxLength:
              response.next_screen.capture_email_otp.validation.email_otp
                .max_length,
            validationRegex:
              response.next_screen.capture_email_otp.validation.email_otp.regex,
          },
        } as EnterEmailOTPStep;
      }
      throw new Error('Unknown next screen');
    } catch (error) {
      return this.handleErrorResponseForEmailOTP(error);
    }
  }

  async submitEmailOTP(
    otpRequest: SubmitEmailOTPRequestDTO
  ): Promise<IDFCAuthStep> {
    try {
      const updatedRequest: SubmitEmailOTPRequestDTO = {
        ...otpRequest,
        tenant_id: this.authProps.tenant,
      };

      const response: SubmitEmailOTPResponseDTO =
        await this.authNetworkAPIHelper.submitEmailOTP(updatedRequest);

      if (response.id_token && response.refresh_token) {
        this.authProps.onAuthSuccess({
          idToken: response.id_token,
          refreshToken: response.refresh_token,
          phone: otpRequest.phone,
        });
        return {
          identifier: DFCAuthStepIdentifiers.AuthCompleteStep,
        } as AuthCompleteStep;
      }

      throw new Error('Unknown next screen: Not yet implemented');
    } catch (error) {
      return this.handleErrorResponseForEmailOTP(error);
    }
  }

  private handleErrorResponseForPhoneOTP(error: any) {
    const knownErrors = [
      'web_login_not_allowed',
      'invalid_user',
      'send_otp_error',
    ];
    if (knownErrors.includes(error?.response?.data?.code)) {
      return {
        identifier: DFCAuthStepIdentifiers.AuthErrorStep,
        errorResponse: error.response.data as DFCAuthErrorModel,
      } as AuthErrorStep;
    }
    throw error;
  }

  private handleErrorResponseForEmailOTP(error: any) {
    const knownErrors = ['existing_user_email', 'invalid_user'];
    if (knownErrors.includes(error?.response?.data?.code)) {
      return {
        identifier: DFCAuthStepIdentifiers.AuthErrorStep,
        errorResponse: error.response.data as DFCAuthErrorModel,
      } as AuthErrorStep;
    } else if (
      error?.response?.data?.code === 'third_party_account_not_found'
    ) {
      return {
        identifier: DFCAuthStepIdentifiers.AuthErrorStep,
        errorResponse: {
          code: error.response.data?.code,
          message: error.response.data?.message,
          details: error.response.data?.details,
          error: error.response.data?.error,
        } as ErrorResponseDTO,
      } as AuthErrorStep;
    }
    throw error;
  }
}
