"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DFCTenant = void 0;
var DFCTenant;
(function (DFCTenant) {
    DFCTenant["DESERVE"] = "deserve";
    DFCTenant["AMC"] = "1bb0d95c-b83e-4b60-82ea-4db59c16df10";
    DFCTenant["CUBI_BUSINESS"] = "c7e5a089-581a-4535-abe5-916d133c381e";
    DFCTenant["EBAY"] = "95274da9-83bb-4d00-b93b-043bec48e993";
    DFCTenant["DCP_CLOUDEDGE_SMB"] = "95274da9-83bb-4d00-b93b-043bec48e993";
})(DFCTenant = exports.DFCTenant || (exports.DFCTenant = {}));
