import React from 'react';
import {
  Typography,
  IconLoaderButton,
  CapturePhoneNumberField,
} from '@dfc-web/ui';

import { EnterPhoneConfigModel } from './EnterPhoneConfigModel';
import { EnterPhoneStep } from './EnterPhoneStep';
import useEnterPhone from './useEnterPhone';

interface EnterPhoneProps {
  config: EnterPhoneConfigModel;
  step: EnterPhoneStep;
}

//Responsibility of any Page
//1. Rendering of component
//2. Alignment of components only
//3. placement of components with config provider
//4. No business logic - Everything should be handled by custom hook
//5. No state management - Everything should be handled by custom hook
//6. No API calls - Everything should be handled by custom hook
//7. No validation - Everything should be handled by custom hook
//8. No error handling - Everything should be handled by custom hook
const EnterPhone: React.FC<EnterPhoneProps> = (props: EnterPhoneProps) => {
  // const [value, setValue] = useState('');
  const {
    submitting,
    error,
    isValidData,
    phoneNumber,
    handlePhoneNumberChange,
    handleSubmit,
  } = useEnterPhone(props.step);

  const config = props.config;

  const onSubmit = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    handleSubmit();
  };

  return (
    <div data-testid={config.identifier}>
      {config.header && (
        <div className="mt-3">
          <Typography config={config.header} />
        </div>
      )}
      <div className="my-6">
        <Typography config={config.title} />
      </div>
      <div className="my-6">
        <Typography config={config.subTitle} />
      </div>
      <form onSubmit={onSubmit}>
        <CapturePhoneNumberField
          config={config.phoneNumberInput}
          value={phoneNumber}
          error={error}
          onChange={(formattedNumber) => {
            handlePhoneNumberChange(formattedNumber);
          }}
        ></CapturePhoneNumberField>
        <div className="pt-16 text-center">
          <IconLoaderButton
            type="button"
            classes='"block m-auto"'
            config={config.nextButton}
            submitting={submitting}
            disabled={!isValidData}
            onClick={onSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default EnterPhone;
