import React, { useContext } from 'react';

import { IDataStore } from './IDataStore';

const DataStoreContext = React.createContext<IDataStore | null>(null);

export const useDataStore = (): IDataStore => {
  const context = useContext(DataStoreContext);
  if (!context) {
    throw new Error('useDataStore must be used within a DataStoreProvider');
  }
  return context;
};

export const DataStoreProvider: React.FC<{
  dataStore: IDataStore;
  children: React.ReactNode;
}> = ({ dataStore, children }) => {
  return (
    <DataStoreContext.Provider value={dataStore}>
      {children}
    </DataStoreContext.Provider>
  );
};
