import { IDataStore } from "./IDataStore";

export class LocalDataStore implements IDataStore {
    storePhoneNumber(phoneNumber: string): void {
        localStorage.setItem("dfc-auth-phoneNumber", phoneNumber);
    }
    getPhoneNumber(): string | null {
        return localStorage.getItem('dfc-auth-phoneNumber')
    }
    storePhoneOTP(phoneOTP: string): void {
        localStorage.setItem("dfc-auth-phoneOTP", phoneOTP);
    }
    getPhoneOTP(): string | null {
        return localStorage.getItem("dfc-auth-phoneOTP");
    }
}