export enum DFCAuthStepIdentifiers {
  AuthErrorStep = 'auth-error-step',
  AuthCompleteStep = 'auth-complete-step',
  SignUpEnterPhone = 'signup-enter-phone',
  SignUpEnterPhoneOTP = 'signup-enter-phone-otp',
  SignUpEnterPhoneOTPTimeout = 'signup-enter-phone-otp-timeout',
  SignUpEnterEmail = 'signup-enter-email',
  SignUpEnterEmailOTP = 'signup-enter-email-otp',
  SignUpEnterEmailOTPTimeout = 'signup-enter-email-otp-timeout',
  SignInEnterPhone = 'signin-enter-phone',
  SignInEnterPhoneOTP = 'signin-enter-phone-otp',
  SignInEnterPhoneOTPTimeout = 'signin-enter-phone-otp-timeout',
  SignInEnterEmail = 'signin-enter-email',
  SignInEnterEmailOTP = 'signin-enter-email-otp',
  SignInEnterEmailOTPTimeout = 'signin-enter-email-otp-timeout',
  NoAccountError = 'no-account-error',
  ExistingAccountError = 'existing-account-error',
}
