"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputFieldWithErrorConfigModel = void 0;
var InputFieldWithErrorConfigModel = (function () {
    function InputFieldWithErrorConfigModel(placeholder, title) {
        this.placeholder = placeholder;
        this.title = title;
    }
    return InputFieldWithErrorConfigModel;
}());
exports.InputFieldWithErrorConfigModel = InputFieldWithErrorConfigModel;
