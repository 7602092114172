"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimerWithResendButtonConfigModel = void 0;
var TimerWithResendButtonConfigModel = (function () {
    function TimerWithResendButtonConfigModel(title, resendButton) {
        this.title = title;
        this.resendButton = resendButton;
    }
    return TimerWithResendButtonConfigModel;
}());
exports.TimerWithResendButtonConfigModel = TimerWithResendButtonConfigModel;
