import React from 'react';
import { AnalyticsEmitter, LogType } from '@dfc-web/core';
import { CustomEventModel, IdentifyEventModel } from '@dfc-web/core';
import { LogEmitter } from '@dfc-web/core';
import { AnalyticsEmitterProvider, LogEmitterProvider } from '@dfc-web/core';

import { DFCAuthFlow } from './models/DFCAuthFlows';
import {
  DFCAuthErrorModel,
  DFCAuthSuccessModel,
} from './models/DFCAuthOutputModel';
import { DFCAuthStepIdentifiers } from './models/DFCAuthStepIdentifiers';
import { DFCAuthTenant } from './models/DFCAuthTenant';
import {
  EnterEmailOTPStep,
  EnterEmailOTPStepUsedFor,
} from './pages/EnterEmailOTP/EnterEmailOTPStep';
import { EnterPhoneStep } from './pages/EnterPhone/EnterPhoneStep';
import { DataStoreProvider } from './services/dataStoreService/DataStoreProvider';
import { IDataStore } from './services/dataStoreService/IDataStore';
import { LocalDataStore } from './services/dataStoreService/LocalDataStore';
import {
  DFCAuthStepProcessorProvider,
  IDFCAuthStep,
} from './stepProcessor/DFCAuthStepProcessorProvider';
import { DFCAuthStepRenderer } from './stepProcessor/DFCAuthStepRenderer';

export interface VerifyEmailOTPDeepLinkProps {
  email: string;
  otp: string;
}
export interface DFCAuthProps {
  imagePrefix: string;
  tenant: DFCAuthTenant;
  flow: DFCAuthFlow;
  verifyEmailOTPDeepLinkProps?: VerifyEmailOTPDeepLinkProps;
  onDismiss: () => void;
  onAuthSuccess: (model: DFCAuthSuccessModel) => void;
  onAuthFailure: (error: DFCAuthErrorModel) => void;
  onAnalyticsEvent: (event: CustomEventModel | IdentifyEventModel) => void;
  onLogMessage: (message: String, additionalInfo: any, type: LogType) => void;
  enableDebugLogs: boolean;
}

const DFCAuth: React.FC<DFCAuthProps> = (authProps: DFCAuthProps) => {
  const analyticsEmitter = new AnalyticsEmitter(authProps.onAnalyticsEvent);
  const dataStore: IDataStore = new LocalDataStore();
  const logEmitter = new LogEmitter(
    authProps.onLogMessage,
    authProps.enableDebugLogs
  );

  const getInitialStep = (): IDFCAuthStep => {
    switch (authProps.flow) {
      case DFCAuthFlow.AuthFlowSignIn:
        return {
          identifier: DFCAuthStepIdentifiers.SignInEnterPhone,
        } as EnterPhoneStep;
      case DFCAuthFlow.AuthFlowSignUp:
        return {
          identifier: DFCAuthStepIdentifiers.SignUpEnterPhone,
        } as EnterPhoneStep;
      case DFCAuthFlow.AuthFlowVerifyEmailOTP:
        return {
          identifier: DFCAuthStepIdentifiers.SignInEnterEmailOTP,
          phone: dataStore.getPhoneNumber(),
          phoneOTP: dataStore.getPhoneOTP(),
          email: authProps.verifyEmailOTPDeepLinkProps?.email || '',
          emailOTP: authProps.verifyEmailOTPDeepLinkProps?.otp || '',
          usedFor: EnterEmailOTPStepUsedFor.VerifyEmailOTPDeepLink,
        } as EnterEmailOTPStep;
    }
  };
  return (
    <DataStoreProvider dataStore={dataStore}>
      <LogEmitterProvider emitter={logEmitter}>
        <AnalyticsEmitterProvider emitter={analyticsEmitter}>
          <DFCAuthStepProcessorProvider initialStep={getInitialStep()}>
            <DFCAuthStepRenderer
              authPackageProps={authProps}
            ></DFCAuthStepRenderer>
          </DFCAuthStepProcessorProvider>
        </AnalyticsEmitterProvider>
      </LogEmitterProvider>
    </DataStoreProvider>
  );
};

export default DFCAuth;
