import {
  TypographyConfigModel,
  IconLoaderButtonConfigModel,
  TimerWithResendButtonConfigModel,
  CaptureOTPFieldConfigModel,
  ButtonConfigModel,
} from '@dfc-web/ui';

import { DFCAuthPageConfigModel } from '../../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';

export class ResendEnterEmailOTPConfigModel {
  public title: TypographyConfigModel;
  public subTitle: TypographyConfigModel;
  public resetButton: ButtonConfigModel;

  constructor() {
    this.title = {} as TypographyConfigModel;
    this.resetButton = {} as ButtonConfigModel;
    this.subTitle = {} as TypographyConfigModel;
  }

  withTitle(title: TypographyConfigModel): ResendEnterEmailOTPConfigModel {
    this.title = title;
    return this;
  }

  withResetButton(
    resetButton: ButtonConfigModel
  ): ResendEnterEmailOTPConfigModel {
    this.resetButton = resetButton;
    return this;
  }

  withSubTitle(
    subTitle: TypographyConfigModel
  ): ResendEnterEmailOTPConfigModel {
    this.subTitle = subTitle;
    return this;
  }
}

export class EnterEmailOTPConfigModel implements DFCAuthPageConfigModel {
  public identifier: DFCAuthStepIdentifiers;
  public header: TypographyConfigModel;
  public title: TypographyConfigModel;
  public subTitle: TypographyConfigModel;
  public otpField: CaptureOTPFieldConfigModel;
  public timerWithResendButton: TimerWithResendButtonConfigModel;
  public nextButton: IconLoaderButtonConfigModel;
  public resendOTPConfig: ResendEnterEmailOTPConfigModel;

  constructor(identifier: DFCAuthStepIdentifiers) {
    this.identifier = identifier;
    this.header = {} as TypographyConfigModel;
    this.title = {} as TypographyConfigModel;
    this.subTitle = {} as TypographyConfigModel;
    this.nextButton = {} as IconLoaderButtonConfigModel;
    this.timerWithResendButton = {} as TimerWithResendButtonConfigModel;
    this.otpField = {} as CaptureOTPFieldConfigModel;
    this.resendOTPConfig = {} as ResendEnterEmailOTPConfigModel;
  }

  withHeader(header: TypographyConfigModel): EnterEmailOTPConfigModel {
    this.header = header;
    return this;
  }

  withTitle(title: TypographyConfigModel): EnterEmailOTPConfigModel {
    this.title = title;
    return this;
  }

  withSubTitle(subTitle: TypographyConfigModel): EnterEmailOTPConfigModel {
    this.subTitle = subTitle;
    return this;
  }

  withOTPField(otpField: CaptureOTPFieldConfigModel): EnterEmailOTPConfigModel {
    this.otpField = otpField;
    return this;
  }

  withTimerResendButton(
    timerWithResendButton: TimerWithResendButtonConfigModel
  ): EnterEmailOTPConfigModel {
    this.timerWithResendButton = timerWithResendButton;
    return this;
  }

  withNextButton(
    nextButton: IconLoaderButtonConfigModel
  ): EnterEmailOTPConfigModel {
    this.nextButton = nextButton;
    return this;
  }

  withResendOTPConfig(
    resendOTPConfig: ResendEnterEmailOTPConfigModel
  ): EnterEmailOTPConfigModel {
    this.resendOTPConfig = resendOTPConfig;
    return this;
  }
}
