"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsEmitter = void 0;
var AnalyticsEmitter = (function () {
    function AnalyticsEmitter(onAnalyticsEvent) {
        this.onAnalyticsEvent = onAnalyticsEvent;
    }
    AnalyticsEmitter.prototype.trackEvent = function (event) {
        this.onAnalyticsEvent(event);
    };
    AnalyticsEmitter.prototype.identify = function (event) {
        this.onAnalyticsEvent(event);
    };
    return AnalyticsEmitter;
}());
exports.AnalyticsEmitter = AnalyticsEmitter;
