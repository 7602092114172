import React, { ReactNode } from 'react';

interface ModalContentWrapperProps {
  children: ReactNode;
}

const ModalContentWrapper: React.FC<ModalContentWrapperProps> = ({
  children,
}) => {
  return (
    <div className="rounded-2xl bg-bg1 pt-4 px-8 sm:px-12 sm:py-10 md:px-16 xl:px-11 xl:pt-[52px] 2xl:px-14 2xl:pt-[72px] w-full h-full">
      {children}
    </div>
  );
};

export default ModalContentWrapper;
