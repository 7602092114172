export class PhoneNumberUtil {
  static unFormattedPhoneNumber(newValue: string): string {
    return newValue
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(/ /g, '')
      .replace('+1', '');
  }

  static validatePhoneNumber(
    newValue: string,
    pattern: RegExp = /^[0-9]{10}\d*$/,
    min_length: number = 10,
    max_length: number = 13
  ): boolean {
    const phoneNumber = this.unFormattedPhoneNumber(newValue);

    const hasUScountryCode = new RegExp(/^1/).test(phoneNumber.toString());
    const isValidEntry = new RegExp(pattern).test(phoneNumber.toString());
    const isInRange =
      Number(phoneNumber) > 1 &&
      phoneNumber.toString().length >= min_length &&
      `+1${phoneNumber.toString()}`.length <= max_length;

    return !hasUScountryCode && isValidEntry && isInRange;
  }

  static getUSPhoneNumber = (phone_number: string): string => {
    return !phone_number.includes('+1') ? `+1${phone_number}` : phone_number;
  };

  static maskPhoneNumber = function (
    str: string | undefined,
    index: number,
    replacement: string
  ) {
    const plainNumber = PhoneNumberUtil.unFormattedPhoneNumber(str || '');

    if (!plainNumber) return '';
    return (
      '+1 ' +
      plainNumber.substring(0, index) +
      replacement +
      plainNumber.substring(index + replacement.replace(/\s/gim, '').length)
    );
  };
}
