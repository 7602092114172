import React from 'react';
import {
  CreateThirdPartyAccountConfigModel,
  IconLoaderButtonConfigModel,
  InputFieldWithErrorConfigModel,
  TypographyConfigModel,
} from '@dfc-web/ui';

import { DFCAuthPageConfigModel } from '../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../models/DFCAuthStepIdentifiers';
import { EnterEmailConfigModel } from '../pages/EnterEmail/EnterEmailConfigModel';
import { IDFCAuthStep } from '../stepProcessor/DFCAuthStepProcessorProvider';
import { NextArrowSVG } from '../ui/NextArrowSVG';
import { Spinner } from '../ui/Spinner';
import { DefaultPageBuilder } from './DefaultPageBuilder';

export class AMCPageBuilder extends DefaultPageBuilder {
  buildAuthErrorPage(step: IDFCAuthStep): DFCAuthPageConfigModel {
    return super.buildAuthErrorPage(step);
  }

  buildAuthCompletePage(): DFCAuthPageConfigModel {
    return super.buildAuthCompletePage();
  }

  //Sign In
  buildSignInEnterPhonePage(): DFCAuthPageConfigModel {
    return super.buildSignInEnterPhonePage();
  }

  buildSignInEnterPhoneOTPPage(): DFCAuthPageConfigModel {
    return super.buildSignInEnterPhoneOTPPage();
  }

  buildSignInEnterEmailOTPPage(): DFCAuthPageConfigModel | undefined {
    return super.buildSignInEnterEmailOTPPage();
  }

  //Sign Up
  buildSignUpEnterPhonePage(): DFCAuthPageConfigModel | undefined {
    return super.buildSignUpEnterPhonePage();
  }

  buildSignUpEnterPhoneOTPPage(): DFCAuthPageConfigModel | undefined {
    return super.buildSignUpEnterPhoneOTPPage();
  }

  buildSignUpEnterEmailPage(): DFCAuthPageConfigModel | undefined {
    return this.buildAMCEnterEmailPage(DFCAuthStepIdentifiers.SignUpEnterEmail);
  }

  buildSignUpEnterEmailOTPPage(): DFCAuthPageConfigModel | undefined {
    return super.buildSignUpEnterEmailOTPPage();
  }

  private buildAMCEnterEmailPage(
    identifier: DFCAuthStepIdentifiers
  ): DFCAuthPageConfigModel {
    const page = new EnterEmailConfigModel(identifier)
      .withTitle(
        new TypographyConfigModel('What’s your AMC Stubs® email?')
          .withVariant('h1')
          .withColor('text-t1-headline')
      )
      .withNextButton(
        new IconLoaderButtonConfigModel(<Spinner />, <NextArrowSVG />)
      )
      .withInputField(new InputFieldWithErrorConfigModel('Email'));

    page.withThirdPartyAccountConfig(
      new CreateThirdPartyAccountConfigModel(
        'Not a member? Join AMC Stubs to apply for a card.',
        'Create AMC Stubs Account',
        'https://www.amctheatres.com/amcstubs/sign-in'
      )
    );

    page.withHeader(
      new TypographyConfigModel(
        identifier == DFCAuthStepIdentifiers.SignUpEnterEmail
          ? 'SIGN UP'
          : 'SIGN IN'
      )
        .withVariant('c3')
        .withColor('text-br1-main')
    );

    return page;
  }
}
