import { DFCAuthTenant } from '../models/DFCAuthTenant';
import { AMCPageBuilder } from './AMCPageBuilder';
import { DefaultPageBuilder } from './DefaultPageBuilder';
import { IAuthPageBuilder } from './IAuthPageBuilder';

export class PageBuilderFactory {
  private tenant: DFCAuthTenant;
  private imagePrefix: string;
  constructor(
    tenant: DFCAuthTenant,
    imagePrefix: string
  ) {
    this.tenant = tenant;
    this.imagePrefix = imagePrefix;
  }

  getBuilder(): IAuthPageBuilder | undefined {
    switch (this.tenant) {
      case DFCAuthTenant.DCP_CLOUDEDGE_SMB:
      case DFCAuthTenant.EBAY:
      case DFCAuthTenant.CUBI_BUSINESS:
        return new DefaultPageBuilder(this.imagePrefix);
      case DFCAuthTenant.AMC: 
        return new AMCPageBuilder(this.imagePrefix);
      default:
        return undefined;
    }
  }
}
