import { EmailValidator } from './EmailValidator';
import { IValidator } from './IValidator';
import { OTPValidator } from './OTPValidator';
import { PhoneValidator } from './PhoneValidator';

export interface Validation {
  minLength: number;
  maxLength: number;
  validationRegex: string;
}

export enum ValidatorType {
  PHONE = 'phone',
  OTP = 'otp',
  EMAIL = 'email',
}

export class Validator {
  static getValidator(
    type: ValidatorType,
    validation: Validation | undefined = undefined
  ): IValidator {
    switch (type) {
      case ValidatorType.PHONE:
        return new PhoneValidator(validation);
      case ValidatorType.OTP:
        return new OTPValidator(validation);
      case ValidatorType.EMAIL:
        return new EmailValidator(validation);
    }
  }
}
