import { IValidator, ValidationResult } from './IValidator';
import { Validation } from './Validator';

export enum OTPValidatorResultCode {
  REQUIRED = 'REQUIRED',
  INVALID_LENGTH = 'INVALID_LENGTH',
}

export interface OTPValidatorResult extends ValidationResult {
  code?: OTPValidatorResultCode;
}

export class OTPValidator implements IValidator {
  validation: Validation = {
    minLength: 6,
    maxLength: 6,
    validationRegex: `^[0-9]{6}\\d*$`,
  };

  constructor(validation: Validation | undefined = undefined) {
    if (validation) {
      this.validation = validation;
    }
  }
  validate(value: string): ValidationResult {
    if (value.length === 0) {
      return {
        isValid: false,
        code: OTPValidatorResultCode.REQUIRED,
      } as OTPValidatorResult;
    }

    const isInRange =
      Number(value) > 1 &&
      value.toString().length >= this.validation.minLength &&
      value.toString().length <= this.validation.maxLength;
    if (!isInRange) {
      return {
        isValid: false,
        code: OTPValidatorResultCode.INVALID_LENGTH,
      } as OTPValidatorResult;
    }

    return {
      isValid: true,
    } as OTPValidatorResult;
  }
}
