"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapturePhoneNumberFieldConfigModel = void 0;
var CapturePhoneNumberFieldConfigModel = (function () {
    function CapturePhoneNumberFieldConfigModel(placeholder, title) {
        this.placeholder = placeholder;
        this.title = title;
    }
    return CapturePhoneNumberFieldConfigModel;
}());
exports.CapturePhoneNumberFieldConfigModel = CapturePhoneNumberFieldConfigModel;
