import React from 'react';

export const Spinner = () => {
  //this is the case where I have to define loading-wp inside ui package and not in other packages
  return (
    <div data-testid="spinner" className="rounded-full loading-wp">
      <div className="loading animate-spin"></div>
    </div>
  );
};
