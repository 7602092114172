import axios, { AxiosResponse } from 'axios';

import { INetworkAPIHelper } from './INetworkAPIHelper';

export class NetworkAPIHelper implements INetworkAPIHelper {
  //TODO: Add axios instance
  // private axiosInstance: AxiosInstance | undefined;

  // initialize(baseURL: string) {
  //   this.axiosInstance = axios.create({
  //     baseURL: baseURL,
  //   });
  // }

  async post(url: string, data: any): Promise<AxiosResponse<any>> {
    try {
      const response = await axios.post(url, data);
      return response;
    } catch (error) {
      // Handle error appropriately
      throw error;
    }
  }

  async get(url: string): Promise<AxiosResponse<any>> {
    // if (!this.axiosInstance) {
    //   throw new Error('Axios instance not initialized');
    // }

    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      // Handle error appropriately
      throw error;
    }
  }
}
