import { useCallback, useContext, useEffect, useState } from 'react';
import { useAnalyticsEmitter } from '@dfc-web/core';

import { OTPValidatorResult } from '../../core/OTPValidator';
import { Validator, ValidatorType } from '../../core/Validator';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';
import { SubmitPhoneOTPRequestDTO } from '../../models/SubmitPhoneOTPRequestDTO';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import { useAuthService } from '../../services/authService/AuthServiceProvider';
import { useDataStore } from '../../services/dataStoreService/DataStoreProvider';
import {
  DFCAuthProcessorContext,
  IDFCAuthStep,
} from '../../stepProcessor/DFCAuthStepProcessorProvider';
// import { useAuthService } from 'services/authService/AuthServiceProvider';

import { EnterPhoneOTPStep } from './EnterPhoneOTPStep';

export enum EnterPhoneOTPUIState {
  ENTER_OTP,
  EXPIRED_OTP,
}

interface EnterOTPState {
  otp: string;
  error: string | undefined;
  isValidData: boolean;
  currentState: EnterPhoneOTPUIState;
}

const useEnterPhoneOTP = (step: EnterPhoneOTPStep) => {
  const { addNewStep } = useContext(DFCAuthProcessorContext);
  const authService = useAuthService();
  const analyticsEmitter = useAnalyticsEmitter();
  const dataStore = useDataStore();

  const otpValidator = Validator.getValidator(
    ValidatorType.OTP,
    step.validation
  );
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<EnterOTPState>({
    otp: '',
    error: undefined,
    isValidData: false,
    currentState: EnterPhoneOTPUIState.ENTER_OTP,
  });

  const handleValidation = useCallback((newValue: string) => {
    // let valid = PhoneNumberUtil.validatePhoneNumber(newValue);
    let result = otpValidator.validate(newValue) as OTPValidatorResult;
    if (result.isValid) {
      setState((prevState) => ({
        ...prevState,
        isValidData: true,
        error: undefined,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isValidData: false,
        error: 'Must be a 6 digit number. ',
      }));
    }
    return result.isValid;
  }, []);

  useEffect(() => {
    trackScreenViewEvent();
  }, []);

  const handleResendOTP = () => {
    trackResendButtonClicked();
    setState((prevState) => ({
      ...prevState,
      otp: '',
      error: undefined,
      currentState: EnterPhoneOTPUIState.ENTER_OTP,
    }));

    authService
      .submitPhoneNumber(step.phone)
      .then((nextStep: IDFCAuthStep) => {
        analyticsEmitter.trackEvent({
          eventName: AnalyticsEvents.api.phoneOtp.resendApiSuccess,
          eventProperties: {},
        });
        addNewStep(nextStep);
      })
      .catch((err) => {
        analyticsEmitter.trackEvent({
          eventName: AnalyticsEvents.api.phoneOtp.resendApiFailed,
          eventProperties: {
            code: err?.response?.data?.code || 'unknown',
            error:
              err?.response?.data?.details?.phone || 'Something went wrong!!!',
          },
        });
        setState((prevState) => ({
          ...prevState,
          error:
            err?.response?.data?.details?.phone || 'Something went wrong!!!',
        }));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const handleOTPChange = (value: string) => {
    handleValidation(value);
    setState((prevState) => ({
      ...prevState,
      otp: value,
    }));
  };

  const handleExpiredOTP = () => {
    trackExpiredOTP();
    setState((prevState) => ({
      ...prevState,
      currentState: EnterPhoneOTPUIState.EXPIRED_OTP,
    }));
  };

  const handleSubmit = () => {
    if (!state.isValidData) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      error: undefined,
    }));

    trackNextButtonEvent();
    const requestBody = {
      phone: step.phone,
      otp: state.otp,
      is_signin_flow:
        step.identifier === DFCAuthStepIdentifiers.SignInEnterPhoneOTP
          ? true
          : false,
      is_phone_number_cached: false,
    } as SubmitPhoneOTPRequestDTO;

    setSubmitting(true);
    authService
      .submitPhoneOTP(requestBody)
      .then((nextStep: IDFCAuthStep) => {
        analyticsEmitter.trackEvent({
          eventName: AnalyticsEvents.api.phoneOtp.apiSuccess,
          eventProperties: {
            nextStep: nextStep.identifier,
          },
        });
        dataStore.storePhoneOTP(state.otp);
        addNewStep(nextStep);
      })
      .catch((err) => {
        analyticsEmitter.trackEvent({
          eventName: AnalyticsEvents.api.phoneOtp.apiFailed,
          eventProperties: {
            code: err?.response?.data?.code || 'unknown',
            error:
              err?.response?.data?.details?.phone || 'Something went wrong!!!',
          },
        });
        if (err?.response?.data?.code === 'expired_otp') {
          setState((prevState) => ({
            ...prevState,
            currentState: EnterPhoneOTPUIState.EXPIRED_OTP,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            error:
              err?.response?.data?.details?.phone || 'Something went wrong!!!',
          }));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const trackResendButtonClicked = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignInEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signIn.phoneOtp.resendClicked,
        eventProperties: {},
      });
    } else if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signUp.phoneOtp.resendClicked,
        eventProperties: {},
      });
    }
  };

  const trackExpiredOTP = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignInEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signIn.phoneOtp.expired,
        eventProperties: {},
      });
    } else if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signUp.phoneOtp.expired,
        eventProperties: {},
      });
    }
  };

  const trackNextButtonEvent = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignInEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signIn.phoneOtp.nextClicked,
        eventProperties: {},
      });
    } else if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signUp.phoneOtp.nextClicked,
        eventProperties: {},
      });
    }
  };

  const trackScreenViewEvent = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignInEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signIn.phoneOtp.screen,
        eventProperties: {},
      });
    } else if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterPhoneOTP) {
      analyticsEmitter.trackEvent({
        eventName: AnalyticsEvents.signUp.phoneOtp.screen,
        eventProperties: {},
      });
    }
  };

  return {
    currentState: state.currentState,
    timerValue: step.timer,
    submitting: submitting,
    isValidData: state.isValidData,
    otp: state.otp,
    error: state.error,
    handleOTPChange,
    handleSubmit,
    handleResendOTP,
    handleExpiredOTP,
  };
};

export default useEnterPhoneOTP;
