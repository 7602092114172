import React from 'react';

import { DFCAuthProps } from '../DFCAuth';
import { DFCAuthStepIdentifiers } from '../models/DFCAuthStepIdentifiers';
import AuthComplete from '../pages/AuthCompleteStep/AuthComplete';
import { AuthCompleteStep } from '../pages/AuthCompleteStep/AuthCompleteStep';
import AuthError from '../pages/AuthError/AuthError';
import { AuthErrorConfigModel } from '../pages/AuthError/AuthErrorConfigModel';
import { AuthErrorStep } from '../pages/AuthError/AuthErrorStep';
import EnterEmail from '../pages/EnterEmail/EnterEmail';
import { EnterEmailConfigModel } from '../pages/EnterEmail/EnterEmailConfigModel';
import { EnterEmailStep } from '../pages/EnterEmail/EnterEmailStep';
import EnterEmailOTP from '../pages/EnterEmailOTP/EnterEmailOTP';
import { EnterEmailOTPConfigModel } from '../pages/EnterEmailOTP/EnterEmailOTPConfigModel';
import { EnterEmailOTPStep } from '../pages/EnterEmailOTP/EnterEmailOTPStep';
import EnterPhone from '../pages/EnterPhone/EnterPhone';
import { EnterPhoneConfigModel } from '../pages/EnterPhone/EnterPhoneConfigModel';
import { EnterPhoneStep } from '../pages/EnterPhone/EnterPhoneStep';
import EnterPhoneOTP from '../pages/EnterPhoneOTP/EnterPhoneOTP';
import { EnterPhoneOTPConfigModel } from '../pages/EnterPhoneOTP/EnterPhoneOTPConfigModel';
import { EnterPhoneOTPStep } from '../pages/EnterPhoneOTP/EnterPhoneOTPStep';
import { AuthService } from '../services/authService/AuthService';
import { AuthServiceProvider } from '../services/authService/AuthServiceProvider';
import { AuthNetworkServiceFactory } from '../services/networkService/AuthNetworkAPIServiceFactory';
import { PageBuilderFactory } from '../tenants/PageBuilderFactory';
import ModalContentWrapper from '../ui/ModalContentWrapper';
import ModalWrapper from '../ui/ModalWrapper';
import { DFCAuthStepBuilder } from './DFCAuthStepBuilder';
import {
  DFCAuthProcessorContext,
  IDFCAuthStepProcessorContextState,
} from './DFCAuthStepProcessorProvider';

interface DFCAuthStepRendererProps {
  authPackageProps: DFCAuthProps;
}

export const DFCAuthStepRenderer: React.FC<DFCAuthStepRendererProps> = (
  props: DFCAuthStepRendererProps
) => {
  const authNetworkService =
    new AuthNetworkServiceFactory().getNetworkService();
  const authService = new AuthService(
    authNetworkService,
    props.authPackageProps
  );

  const { currentStep } = React.useContext(
    DFCAuthProcessorContext
  ) as IDFCAuthStepProcessorContextState;
  const pageBuilder = new PageBuilderFactory(
    props.authPackageProps.tenant,
    props.authPackageProps.imagePrefix
  ).getBuilder();

  const wrapStepInModal = (content: JSX.Element) => {
    return (
      <ModalWrapper closeHandler={props.authPackageProps.onDismiss}>
        <ModalContentWrapper>
          <AuthServiceProvider authService={authService}>
            {content}
          </AuthServiceProvider>
        </ModalContentWrapper>
      </ModalWrapper>
    );
  };
  const renderStep = () => {
    if (!pageBuilder) {
      return wrapStepInModal(<div>Unknown Tenant Identified</div>);
    }

    const config = new DFCAuthStepBuilder(pageBuilder).build(currentStep);

    if (!config) {
      return wrapStepInModal(<div>Unknown Step for DFCAuthStepBuilder</div>);
    }

    switch (currentStep.identifier) {
      case DFCAuthStepIdentifiers.AuthErrorStep:
        return (
          <AuthServiceProvider authService={authService}>
            <AuthError
              config={config as AuthErrorConfigModel}
              step={currentStep as AuthErrorStep}
            ></AuthError>
          </AuthServiceProvider>
        );
      case DFCAuthStepIdentifiers.AuthCompleteStep:
        return (
          <AuthComplete step={currentStep as AuthCompleteStep}></AuthComplete>
        );
      case DFCAuthStepIdentifiers.SignUpEnterPhone:
      case DFCAuthStepIdentifiers.SignInEnterPhone:
        const step = currentStep as EnterPhoneStep;
        return wrapStepInModal(
          <EnterPhone
            step={step}
            config={config as EnterPhoneConfigModel}
          ></EnterPhone>
        );
      case DFCAuthStepIdentifiers.SignUpEnterPhoneOTP:
      case DFCAuthStepIdentifiers.SignInEnterPhoneOTP:
        const stepData = currentStep as EnterPhoneOTPStep;
        return wrapStepInModal(
          <EnterPhoneOTP
            step={stepData}
            config={config as EnterPhoneOTPConfigModel}
          ></EnterPhoneOTP>
        );
      case DFCAuthStepIdentifiers.SignUpEnterEmail:
        return wrapStepInModal(
          <EnterEmail
            step={currentStep as EnterEmailStep}
            config={config as EnterEmailConfigModel}
          ></EnterEmail>
        );
      case DFCAuthStepIdentifiers.SignUpEnterEmailOTP:
      case DFCAuthStepIdentifiers.SignInEnterEmailOTP:
        return wrapStepInModal(
          <EnterEmailOTP
            step={currentStep as EnterEmailOTPStep}
            config={config as EnterEmailOTPConfigModel}
          ></EnterEmailOTP>
        );
    }
  };

  return <div data-testid="dfc-auth-step-renderer">{renderStep()}</div>;
};
