import { useContext } from 'react';

import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';
import { useAuthService } from '../../services/authService/AuthServiceProvider';
// import { AnalyticsEvents } from '../../services/analytics/Analytics';
// import { useAnalyticsService } from '../../services/analytics/AnalyticsServiceProvider';
import { DFCAuthProcessorContext } from '../../stepProcessor/DFCAuthStepProcessorProvider';
import { ActionButton } from './AuthErrorConfigModel';
import { AuthErrorStep } from './AuthErrorStep';

const useAuthError = (_step: AuthErrorStep) => {
  const { popTo } = useContext(DFCAuthProcessorContext);
  const authService = useAuthService();

  // const analyticsService = useAnalyticsService();

  // const trackAuthError = () => {
  //   if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterEmail) {
  //     analyticsService.trackEvent({
  //       eventName: AnalyticsEvents.auth_email_next_clicked,
  //       eventProperties: {},
  //     });
  //   }
  // };

  const handleInternalRedirect = (button: ActionButton) => {
    if (button.redirectTo === DFCAuthStepIdentifiers.SignInEnterPhone) {
      popTo(DFCAuthStepIdentifiers.SignInEnterPhone);
    } else if (button.redirectTo === DFCAuthStepIdentifiers.SignUpEnterEmail) {
      if (popTo(DFCAuthStepIdentifiers.SignUpEnterEmail)) {
        return;
      } else {
        authService.closeAuthSession({
          code: 'INTERNAL_REDIRECT_ERROR',
          message: 'Internal redirect error',
        });
      }
    }
  };
  return {
    handleInternalRedirect: handleInternalRedirect,
  };
};

export default useAuthError;
