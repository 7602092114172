"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypographyConfigModel = void 0;
var TypographyConfigModel = (function () {
    function TypographyConfigModel(text) {
        this.text = text;
    }
    TypographyConfigModel.prototype.withColor = function (color) {
        this.color = color;
        return this;
    };
    TypographyConfigModel.prototype.withText = function (text) {
        this.text = text;
        return this;
    };
    TypographyConfigModel.prototype.withVariant = function (variant) {
        this.variants = variant;
        return this;
    };
    return TypographyConfigModel;
}());
exports.TypographyConfigModel = TypographyConfigModel;
