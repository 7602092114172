import { DFCAuthPageConfigModel } from '../models/DFCAuthPageConfigModel';
import { DFCAuthStepIdentifiers } from '../models/DFCAuthStepIdentifiers';
import { IAuthPageBuilder } from '../tenants/IAuthPageBuilder';
import { IDFCAuthStep } from './DFCAuthStepProcessorProvider';

interface IDFCAuthStepBuilder {
  build(step: IDFCAuthStep): DFCAuthPageConfigModel | undefined;
}

export class DFCAuthStepBuilder implements IDFCAuthStepBuilder {
  private pageBuilder: IAuthPageBuilder;
  constructor(pageBuilder: IAuthPageBuilder) {
    this.pageBuilder = pageBuilder;
  }

  build(step: IDFCAuthStep): DFCAuthPageConfigModel | undefined {
    switch (step.identifier) {
      case DFCAuthStepIdentifiers.AuthErrorStep:
        return this.pageBuilder.buildAuthErrorPage(step);
      case DFCAuthStepIdentifiers.AuthCompleteStep:
        return this.pageBuilder.buildAuthCompletePage();
      case DFCAuthStepIdentifiers.SignUpEnterPhone:
        return this.pageBuilder.buildSignUpEnterPhonePage();
      case DFCAuthStepIdentifiers.SignUpEnterPhoneOTP:
        return this.pageBuilder.buildSignUpEnterPhoneOTPPage();
      case DFCAuthStepIdentifiers.SignUpEnterEmail:
        return this.pageBuilder.buildSignUpEnterEmailPage();
      case DFCAuthStepIdentifiers.SignUpEnterEmailOTP:
        return this.pageBuilder.buildSignUpEnterEmailOTPPage();
      case DFCAuthStepIdentifiers.SignInEnterPhone:
        return this.pageBuilder.buildSignInEnterPhonePage();
      case DFCAuthStepIdentifiers.SignInEnterPhoneOTP:
        return this.pageBuilder.buildSignInEnterPhoneOTPPage();
      case DFCAuthStepIdentifiers.SignInEnterEmailOTP:
        return this.pageBuilder.buildSignInEnterEmailOTPPage();
      default:
        return undefined;
    }
  }
}
