import { Validation } from '../../core/Validator';
import { IDFCAuthStep } from '../../stepProcessor/DFCAuthStepProcessorProvider';

export enum EnterEmailOTPStepUsedFor {
  VerifyEmailOTPDeepLink = 'verifyEmailOTPDeepLink',
  EnterEmailOTP = 'enterEmailOTP',
}
export interface EnterEmailOTPStep extends IDFCAuthStep {
  phone: string;
  phoneOTP: string;
  otpHelpText: string;
  email: string;
  timer: number;
  validation: Validation;

  usedFor?: EnterEmailOTPStepUsedFor;
  emailOTP?: string;
}
