import React from 'react';
import {
  Typography,
  IconLoaderButton,
  TimerWithResendButton,
  CaptureOTPField,
  TypographyConfigModel,
  Button,
} from '@dfc-web/ui';

import { PhoneNumberUtil } from '../../util/PhoneNumberUtil';
// import { DFCAuthProcessorContext } from '../../stepProcessor/DFCAuthStepProcessorProvider';
import { EnterPhoneOTPConfigModel } from './EnterPhoneOTPConfigModel';
import { EnterPhoneOTPStep } from './EnterPhoneOTPStep';
import useEnterPhoneOTP, { EnterPhoneOTPUIState } from './useEnterPhoneOTP';

interface EnterPhoneOTPProps {
  step: EnterPhoneOTPStep;
  config: EnterPhoneOTPConfigModel;
}

const EnterPhoneOTP: React.FC<EnterPhoneOTPProps> = (
  props: EnterPhoneOTPProps
) => {
  // const { addNewStep, goBack } = useContext(DFCAuthProcessorContext) || {};
  const config = props.config;
  const {
    currentState,
    timerValue,
    submitting,
    isValidData,
    handleSubmit,
    otp,
    error,
    handleOTPChange,
    handleResendOTP,
    handleExpiredOTP,
  } = useEnterPhoneOTP(props.step);

  const onSubmit = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    handleSubmit();
  };

  const onResendButtonExpiredOTP = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    handleResendOTP();
  };

  const renderExpiredOTP = () => {
    const updatedSubtitle = `${config.resendOTPConfig.subTitle.text}`.replace(
      '@@@',
      `${PhoneNumberUtil.maskPhoneNumber(props.step.phone, -1, 'XXX XXX- ')}`
    );
    const updatedSubtitleConfig = {
      ...config.resendOTPConfig.subTitle,
      text: updatedSubtitle,
    } as TypographyConfigModel;

    return (
      <>
        <div className="my-6">
          <Typography config={config.resendOTPConfig.title} />
        </div>
        <div className="my-6">
          <Typography config={updatedSubtitleConfig} />
        </div>
        <div className="text-center p-4 x-sm:px-24">
          <Button
            config={config.resendOTPConfig.resetButton}
            onClick={onResendButtonExpiredOTP}
            classNames="p-4 x-sm:px-24 rounded-md"
            data-testid="resend-otp-button"
          />
        </div>
      </>
    );
  };

  const renderEnterPhoneOTP = () => {
    const updatedSubtitle = `${
      config.subTitle.text
    } ${PhoneNumberUtil.maskPhoneNumber(props.step.phone, -1, 'XXX XXX- ')}`;
    const updatedSubtitleConfig = {
      ...config.subTitle,
      text: updatedSubtitle,
    } as TypographyConfigModel;

    return (
      <>
        <div className="my-6">
          <Typography config={config.title} />
        </div>
        <div className="my-6">
          <Typography config={updatedSubtitleConfig} />
        </div>
        <CaptureOTPField
          config={config.otpField}
          value={otp}
          error={error}
          onChange={(val: string) => {
            handleOTPChange(val);
          }}
        />
        <TimerWithResendButton
          onResend={handleResendOTP}
          onTimerEnd={handleExpiredOTP}
          timerValue={timerValue}
          config={config.timerWithResendButton}
        />
        <div className="text-center">
          <IconLoaderButton
            type="button"
            classes='"block m-auto"'
            config={config.nextButton}
            submitting={submitting}
            disabled={!isValidData}
            onClick={onSubmit}
          />
        </div>
      </>
    );
  };
  return (
    <div data-testid={config.identifier}>
      {config.header && (
        <div className="mt-3">
          <Typography config={config.header} />
        </div>
      )}
      {currentState === EnterPhoneOTPUIState.ENTER_OTP
        ? renderEnterPhoneOTP()
        : renderExpiredOTP()}
    </div>
  );
};

export default EnterPhoneOTP;
